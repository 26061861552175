import { Divider, Flex, Text } from "@chakra-ui/react";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { useParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import {
  defaultStyles as toolTipDefaultStyles,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { timeFormat } from "d3-time-format";
import { useMemo, useRef } from "react";
import type { AnalyticsActiveRidersDetail } from "../../../api/types-and-hooks";
import styles from "../Insights.module.css";
import { DotIcon } from "./DotIcon";

type TooltipData = {
  formattedDate: string;
  totalCount: number;
};

interface ActiveRidersChartProps {
  dataset: Array<AnalyticsActiveRidersDetail>;
}

export function ActiveRidersBarChart({ dataset }: ActiveRidersChartProps) {
  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });
  const tooltipTimeoutRef = useRef<number | null>(null);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef: tooltipContainerRef, TooltipInPortal } =
    useTooltipInPortal({ scroll: true });

  const maxCount = Math.max(...dataset.map((d) => d.totalCount));
  const countDigits = Math.ceil(Math.log10(maxCount));

  const commasCount = Math.floor(countDigits / 3);
  const paddingMultiplier = 15 + commasCount * 3;

  const yLabelMarginRight =
    10 + Math.max(countDigits - 1, 0) * paddingMultiplier;
  const yLabelMarginLeft = 30 - countDigits * 10;
  const xLabelSize = 33.5;

  const xMax = width - yLabelMarginLeft - yLabelMarginRight;
  const yMax = height - xLabelSize;

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: dataset.map((d) => new Date(d.date)),
        padding: 0.3,
        range: [0, xMax],
      }),
    [dataset, xMax],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [0, maxCount],
      }),
    [maxCount, yMax],
  );

  const formatDate = timeFormat("%b ‘%y");

  return (
    <Flex
      ref={parentRef}
      h={275}
      width="full"
      className={styles.ChartBarContainer}
    >
      {width < 10 ? null : (
        <svg width={width} height={height} ref={tooltipContainerRef}>
          <title>Active Riders Chart</title>
          <GridRows
            scale={yScale}
            width={width}
            numTicks={2}
            top={0}
            strokeDasharray="8,5"
            className={styles.ChartBarGrid}
          />
          <Group left={yLabelMarginLeft + yLabelMarginRight}>
            {dataset.map((d) => {
              const date = new Date(d.date);
              const total = d.totalCount;

              const barWidth = xScale.bandwidth();
              const barHeight = yMax - (yScale(total) ?? 0);
              const barX = xScale(date) || 0;
              const barY = yMax - barHeight;

              return (
                <Bar
                  key={`bar-${d.date}`}
                  className={styles.ChartBar}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  rx={6}
                  onMouseLeave={() => {
                    tooltipTimeoutRef.current = window.setTimeout(() => {
                      hideTooltip();
                    }, 200);
                  }}
                  onMouseMove={(event) => {
                    if (tooltipTimeoutRef.current) {
                      clearTimeout(tooltipTimeoutRef.current);
                    }

                    const eventSvgCoords = localPoint(event);
                    const left = (barX || 0) + barWidth / 2;

                    showTooltip({
                      tooltipData: {
                        formattedDate: formatDate(new Date(d.date)),
                        totalCount: total,
                      },
                      tooltipTop: eventSvgCoords?.y,
                      tooltipLeft: left,
                    });
                  }}
                />
              );
            })}
            <AxisBottom
              left={0}
              top={yMax}
              scale={xScale}
              tickFormat={formatDate}
              numTicks={xMax / ((xScale.bandwidth() * dataset.length) / 5)}
              axisLineClassName={styles.ChartBarXAxisLine}
              tickLineProps={{
                strokeWidth: width < 1000 ? 1 : 0,
                stroke: "var(--core-border)",
              }}
              tickLabelProps={{
                fontSize: "1rem",
                strokeWidth: 0,
                fill: "var(--fg-color)",
                transform: "translate(0, 8)",
              }}
            />
            <AxisLeft
              top={20}
              left={0}
              scale={yScale}
              tickFormat={(d) => d.toLocaleString()}
              hideAxisLine
              hideZero
              numTicks={2}
              tickLineProps={{ strokeWidth: 0 }}
              tickLabelProps={{
                className: styles.ChartBarYAxisLine__TickLabel,
              }}
            />
          </Group>
        </svg>
      )}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...toolTipDefaultStyles,
            width: "220px",
            borderRadius: "12px",
            fontSize: "14px",
            border: "1px solid var(--core-border)",
            padding: 0,
            background: "#FFF",
            boxShadow:
              "0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Flex direction="column" w="full" color="var(--fg-color-secondary)">
            <Flex w="full" justifyContent="space-between" px={3} py={2}>
              <Text fontWeight={600}>Riders</Text>
              <Text color="var(--fg-color)">{tooltipData.formattedDate}</Text>
            </Flex>
            <Divider w="full" />
            <Flex w="full" justifyContent="space-between" px={3} py={2}>
              <Text>
                <DotIcon color="var(--chart-bars-active)" /> Active riders
              </Text>
              <Text>{tooltipData.totalCount}</Text>
            </Flex>
          </Flex>
        </TooltipInPortal>
      )}
    </Flex>
  );
}

export default ActiveRidersBarChart;
