const Button = {
  baseStyle: {
    fontWeight: "bold",
  },
  sizes: {
    lg: {
      px: 4,
    },
  },
  variants: {
    outline: {
      borderWidth: 2,
    },
    block: {
      width: "100%",
      bg: "primary.400",
      color: "white",
      _hover: {
        bg: "primary.500",
        _disabled: {
          bg: "primary.400",
        },
      },
      _active: {
        bg: "primary.600",
      },
    },
  },
  defaultProps: {
    colorScheme: "panda",
  },
};

export default Button;
