import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const helpers = createMultiStyleConfigHelpers(["input", "field"]);

const Input = helpers.defineMultiStyleConfig({
  baseStyle: {
    field: {
      color: "panda.500",
      borderWidth: 2,
      _placeholder: {
        color: "panda.500",
        opacity: 0.6,
      },
    },
  },
  variants: {
    filled: {
      field: {
        bg: "customs.mutedBackground",
        _hover: {
          bg: "customs.mutedBackgroundHover",
        },
        _focusVisible: {
          bg: "customs.mutedBackground",
          borderColor: "panda.500",
        },
        _invalid: {},
      },
    },
  },
  defaultProps: {
    variant: "filled",
  },
});

export default Input;
