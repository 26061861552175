import * as Sentry from "@sentry/react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

import Layout from "../components/Layout";
import RequireAuth from "../components/RequireAuth";
import Home from "../domains/Home";
import Integrations from "../domains/Integrations";
import Login from "../domains/Login";
import SpokeInvoices from "../domains/SpokeInvoices";
import { SpokeInvoiceDetails } from "../domains/SpokeInvoices/Details";
import LeaseCreationForm from "../domains/Spokes/components/LeaseCreationForm";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(RouterRoutes);

export default function Routes() {
  return (
    <SentryRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/login/e/:email" element={<Login />} />
      <Route element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/lease_form" element={<LeaseCreationForm />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/spoke_invoices" element={<SpokeInvoices />} />
          <Route path="/spoke_invoices/:id" element={<SpokeInvoiceDetails />} />
        </Route>
      </Route>
    </SentryRoutes>
  );
}
