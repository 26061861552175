import { ChakraProvider } from "@chakra-ui/react";

import { ApolloProvider } from "@apollo/client";
import AuthProvider from "./components/AuthProvider";
import Routes from "./services/Routes";
import { apolloClient } from "./services/apolloClient";
import theme from "./theme";
import Fonts from "./theme/components/Fonts";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <Routes />
        </ApolloProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
