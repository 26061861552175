import { Formik } from "formik";
import { ObjectSchema } from "yup";

// biome-ignore lint/suspicious/noExplicitAny: stay away from this
export const isRangeItem = (value: any) => {
  return (
    value &&
    Object.prototype.hasOwnProperty.call(value, "min") &&
    Object.prototype.hasOwnProperty.call(value, "max")
  );
};

export const getModelAttributesToSave = (
  // biome-ignore lint/suspicious/noExplicitAny: stay away from this
  model: any,
  // biome-ignore lint/suspicious/noExplicitAny: stay away from this
  schema: any,
  // biome-ignore lint/suspicious/noExplicitAny: stay away from this
  options: any,
) => {
  const { nullifyEmptyStrings, recursiveCleanup } = options;
  // biome-ignore lint/suspicious/noExplicitAny: stay away from this
  const objectToSend: any = {};

  for (const [key, type] of Object.entries(schema.fields)) {
    if (model) {
      let value = model[key];

      if (recursiveCleanup && type instanceof ObjectSchema) {
        value = getModelAttributesToSave(value, type, options);
      }

      if (nullifyEmptyStrings) {
        if (value === "") {
          value = null;
        } else if (type instanceof ObjectSchema && isRangeItem(value)) {
          if (value.min === "") {
            value = null;
          } else if (value.max === "" || value.max < value.min) {
            value = { ...value, max: null };
          }
        }
      }

      objectToSend[key] = value;
    }
  }

  return objectToSend;
};

export default function FormikEnhanced({
  children,
  onSubmit,
  nullifyEmptyStrings = false,
  recursiveCleanup = false,
  ...rest
  // biome-ignore lint/suspicious/noExplicitAny: stay away from this
}: any): JSX.Element {
  // biome-ignore lint/suspicious/noExplicitAny: stay away from this
  const handleSubmit = (values: any, formikHelpers: any) => {
    if (!rest.validationSchema) {
      return onSubmit(values, formikHelpers, undefined);
    }

    onSubmit(
      values,
      formikHelpers,
      getModelAttributesToSave(values, rest.validationSchema, {
        nullifyEmptyStrings,
        recursiveCleanup,
      }),
    );

    return undefined;
  };

  return (
    <Formik {...rest} onSubmit={handleSubmit}>
      {children}
    </Formik>
  );
}
