import { Icon, type IconProps } from "@chakra-ui/react";

const HourglassCircleIcon = ({
  size,
  ...props
}: IconProps & { size?: string | number | undefined }) => (
  <Icon viewBox="0 0 14 20" w={size} h={size} {...props}>
    <path
      fill="none"
      d="M1.5 5H12.5M7 10C5.4087 10 3.88258 10.6321 2.75736 11.7574C1.63214 12.8826 1 14.4087 1 16V18C1 18.2652 1.10536 18.5196 1.29289 18.7071C1.48043 18.8946 1.73478 19 2 19H12C12.2652 19 12.5196 18.8946 12.7071 18.7071C12.8946 18.5196 13 18.2652 13 18V16C13 14.4087 12.3679 12.8826 11.2426 11.7574C10.1174 10.6321 8.5913 10 7 10ZM7 10C5.4087 10 3.88258 9.36786 2.75736 8.24264C1.63214 7.11742 1 5.5913 1 4V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H12C12.2652 1 12.5196 1.10536 12.7071 1.29289C12.8946 1.48043 13 1.73478 13 2V4C13 5.5913 12.3679 7.11742 11.2426 8.24264C10.1174 9.36786 8.5913 10 7 10Z"
      stroke="#332B04"
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default HourglassCircleIcon;
