import { Center, Flex, Heading, VStack } from "@chakra-ui/react";
import InvoicesTable from "./InvoicesTable";

export default function SpokeInvoices() {
  return (
    <>
      <Center p={12}>
        <VStack w="full" maxW="100rem">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            w="full"
            mb="48px"
          >
            <Heading>Invoices</Heading>
            {/* TODO: Show filters and send them to the table */}
            {/* <Filters /> */}
          </Flex>
          <InvoicesTable />
        </VStack>
      </Center>
    </>
  );
}
