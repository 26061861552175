import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getBackendConfig } from "./configs";
import { auth, signOut as logout } from "./firebase";

function isUnauthorized(networkError, graphQLErrors) {
  if (networkError?.statusCode === 401) return true;

  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors.some(
      (innerError) => innerError?.extensions?.code === "UNAUTHORIZED",
    );
  }

  return false;
}

const uri = `${getBackendConfig()}/graphql`;
const httpLink = new HttpLink({ uri, fetch, credentials: "include" });
const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (isUnauthorized(networkError, graphQLErrors)) logout();
});

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const accessToken = await auth.currentUser?.getIdToken();
  const authorization = accessToken ? `Bearer ${accessToken}` : null;

  operation.setContext(({ headers = {} }) => ({
    headers: { ...headers, authorization },
  }));

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authMiddleware, logoutLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});

const retryUnauthenticated = async (func) => {
  try {
    return func();
  } catch (e) {
    if (isUnauthorized(e)) {
      await auth.currentUser?.getIdToken(true);

      return func();
    }
    throw e;
  }
};

export const apolloQuery = async (options) =>
  retryUnauthenticated(async () => apolloClient.query(options));

export const apolloMutate = async (options) =>
  retryUnauthenticated(async () => apolloClient.mutate(options));
