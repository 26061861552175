import {
  Center,
  type ChakraProps,
  Flex,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Card } from "../../../components/Card";
import CurrencyText from "../../../components/CurrencyText";
import CurrencyDollarIcon from "../../../components/Icons/CurrencyDollarIcon";

type Props = {
  totalRetailDiscountedPrice: number;
  discountPercentage?: number;
};

export default function CardPriceForRidepanda({
  totalRetailDiscountedPrice,
  discountPercentage = 10,
  ...props
}: Props & ChakraProps) {
  return (
    <Card
      boxShadow="none"
      padding={5}
      width="100%"
      border="2px solid #EAEAEA"
      {...props}
    >
      <Flex direction="row">
        <Center mr={3}>
          <CurrencyDollarIcon size="1.5rem" />
        </Center>
        <VStack align="self-start" spacing={0}>
          <Text fontSize="1rem" textTransform="uppercase" fontWeight="bold">
            Final price to Ridepanda
          </Text>
          <Text fontSize="sm">
            Total pre-tax retail price - {discountPercentage}%
          </Text>
        </VStack>
        <Spacer />
        <Center mr={3}>
          <Text fontWeight="bold" lineHeight="140%">
            <CurrencyText
              currencyDisplay="narrowSymbol"
              value={totalRetailDiscountedPrice}
              decimals={2}
            />
          </Text>
        </Center>
      </Flex>
    </Card>
  );
}
