import { Divider, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { curveLinear } from "@visx/curve";
import { LegendOrdinal } from "@visx/legend";
import { ParentSize } from "@visx/responsive";
import { defaultStyles as toolTipDefaultStyles } from "@visx/tooltip";
import {
  Axis,
  DataContext,
  DataProvider,
  LineSeries,
  Tooltip,
  type TooltipData,
  XYChart,
  buildChartTheme,
} from "@visx/xychart";
import { timeFormat } from "d3-time-format";
import { useContext } from "react";
import type { AnalyticsActivityDetail } from "../../../api/types-and-hooks";
import styles from "../Insights.module.css";
import { DotIcon } from "./DotIcon";

const theme = buildChartTheme({
  backgroundColor: "white",
  colors: [
    "var(--chart-lines-green1)",
    "var(--chart-lines-green2)",
    "var(--chart-lines-green3)",
  ],
  gridColor: "var(--core-border)",
  gridColorDark: "#1d1b38",
  svgLabelBig: { fill: "#1d1b38" },
  tickLength: 8,
});

function ChartLegend() {
  const { colorScale } = useContext(DataContext);

  if (!colorScale) {
    return null;
  }

  return (
    <LegendOrdinal
      direction="row"
      itemMargin="0 24px 0 0"
      scale={colorScale}
      labelFormat={(label) => label.replace("-", " ")}
      legendLabelProps={{ color: "white" }}
      shape="circle"
      style={{
        backgroundColor: "white",
        color: "var(--fg-color-secondary)",
        marginLeft: "24px",
        marginTop: "24px",
        display: "flex", // required in addition to `direction` if overriding styles
        position: "absolute",
        top: 0,
      }}
    />
  );
}

const dateFormat = timeFormat("%m-%d");

interface ChartTooltipProps {
  tooltipData: TooltipData<AnalyticsActivityDetail> | undefined;
}

function ChartTooltip({ tooltipData }: ChartTooltipProps) {
  const datum = tooltipData?.nearestDatum?.datum;

  if (!datum) {
    return null;
  }

  return (
    <Flex
      direction="column"
      w={220}
      color="var(--fg-color-secondary)"
      py={3}
      gap={3}
    >
      <HStack w="full" justifyContent="space-between" px={3}>
        <Text fontWeight={600}>Portal Activity</Text>
        <Text color="var(--fg-color)">{dateFormat(new Date(datum.date))}</Text>
      </HStack>
      <Divider w="full" />
      <VStack direction="column" w="full" px={3} gap={1}>
        <Flex w="full" justifyContent="space-between">
          <Text>
            <DotIcon color="var(--chart-lines-green3)" /> Orders
          </Text>
          <Text>{datum.ordersCount}</Text>
        </Flex>
      </VStack>
    </Flex>
  );
}

interface ChartProps {
  dataset: Array<AnalyticsActivityDetail>;
}

export function OrdersXYChart({ dataset }: ChartProps) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <DataProvider
          xScale={{ type: "band", paddingInner: 0.3 }}
          yScale={{ type: "linear" }}
          theme={theme}
        >
          <XYChart
            height={height} // Use parent height
            width={width}
          >
            <LineSeries
              dataKey="Orders"
              data={dataset}
              xAccessor={(d) => d.date}
              yAccessor={(d) => d.ordersCount}
              curve={curveLinear}
              strokeWidth={3}
            />
            <Axis
              key="time-axis-bottom"
              orientation="bottom"
              numTicks={10}
              tickFormat={(d) => dateFormat(new Date(d))}
              tickLabelProps={{
                fontSize: "1rem",
                fontWeight: "400",
                fill: "var(--fg-color-tertiary)",
                transform: "translate(0, 5)",
              }}
              tickLineProps={{ stroke: "var(--core-border)", strokeWidth: 1 }}
              tickTransform="translate(0, -5)"
              tickLength={10}
              axisLineClassName={styles.ActivityXYChart__AxisLine}
              top={height - 35}
            />
            <Tooltip<AnalyticsActivityDetail>
              snapTooltipToDatumX
              showVerticalCrosshair
              showSeriesGlyphs
              detectBounds
              debounce={300}
              style={{
                ...toolTipDefaultStyles,
                width: "220px",
                borderRadius: "12px",
                fontSize: "14px",
                border: "1px solid var(--core-border)",
                padding: 0,
                background: "#FFF",
                boxShadow:
                  "0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12)",
              }}
              renderTooltip={({ tooltipData }) => (
                <ChartTooltip tooltipData={tooltipData} />
              )}
            />
          </XYChart>
          <ChartLegend />
        </DataProvider>
      )}
    </ParentSize>
  );
}

export default OrdersXYChart;
