import { Icon, type IconProps } from "@chakra-ui/react";

export default function InvoiceIcon({ ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 30 30" fill="none" {...props}>
      <g clipPath="url(#clip0_368_3345)">
        <path
          d="M18.75 26.25H7.5C6.50544 26.25 5.55161 25.8549 4.84835 25.1517C4.14509 24.4484 3.75 23.4946 3.75 22.5V21.25H16.25M18.75 26.25C18.087 26.25 17.4511 25.9866 16.9822 25.5178C16.5134 25.0489 16.25 24.413 16.25 23.75V21.25M18.75 26.25C19.413 26.25 20.0489 25.9866 20.5178 25.5178C20.9866 25.0489 21.25 24.413 21.25 23.75M16.25 21.25H6.25V7.5C6.25 6.50544 6.64509 5.55161 7.34835 4.84835C8.05161 4.14509 9.00544 3.75 10 3.75H23.75C23.4233 3.75001 23.0981 3.81406 22.7933 3.9403M21.25 23.75V6.25M21.25 23.75V8.75M21.25 6.25C21.25 5.75555 21.3966 5.2722 21.6713 4.86108C21.946 4.44995 22.3365 4.12952 22.7933 3.9403M21.25 6.25V8.75M22.7933 3.9403C23.2501 3.75108 23.7528 3.70157 24.2377 3.79804C24.7227 3.8945 25.1681 4.1326 25.5178 4.48223C25.8674 4.83187 26.1055 5.27732 26.202 5.76228C26.2984 6.24723 26.2489 6.74989 26.0597 7.20671C25.8705 7.66353 25.5501 8.05397 25.1389 8.32868C24.7278 8.60338 24.2445 8.75 23.75 8.75H21.25M11.25 8.75H16.25M11.25 13.75H16.25"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_368_3345">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
