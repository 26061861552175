import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import getEnv from "../utils/getEnv";

export default function initializeSentry() {
  const environment = getEnv("DATA_ENV");

  if (environment === "development") {
    return;
  }

  Sentry.init({
    dsn: getEnv("SENTRY_DSN"),
    release: getEnv("SENTRY_RELEASE"),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment,
    tracePropagationTargets: [getEnv("GRAPHQL_HOST") || "localhost"],
    tracesSampleRate: environment === "production" ? 0.2 : 1.0,
  });
}
