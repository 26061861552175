import { type ChakraProps, Flex, Text } from "@chakra-ui/react";

type Props = { step: string; title: string; subtitle: string };

export default function Heading({
  step,
  title,
  subtitle,
  ...props
}: Props & ChakraProps) {
  return (
    <Flex color="panda.500" direction="column" {...props}>
      <Text opacity={0.8} mb={6}>
        Step {step}
      </Text>
      <Text fontSize="3xl" fontWeight={700} mb={2}>
        {title}
      </Text>
      <Text>{subtitle}</Text>
    </Flex>
  );
}
