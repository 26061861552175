import type { Money } from "../api/types-and-hooks";

export function formatMoney(
  money: Money | number | string | undefined,
  currencyDisplay?: "code" | "symbol" | "name" | "narrowSymbol",
) {
  let amountInDecimals = money;

  if (money === undefined || money === "") return "";

  if (typeof money === "object") {
    const { amount, currencyCode } = money;
    amountInDecimals = amount;

    return Number(amountInDecimals).toLocaleString(undefined, {
      style: "currency",
      currency: currencyCode,
      currencyDisplay,
    });
  }

  return Number(amountInDecimals).toLocaleString(undefined, {
    style: "currency",
    currency: "USD",
    currencyDisplay,
  });
}

export default formatMoney;
