import { Container, Heading, Link, Text } from "@chakra-ui/react";
import Header from "../../components/Layout/Header";

export default function Integrations() {
  return (
    <>
      <Header title="integrations" />

      <Container maxW="container.md" p={5}>
        <Heading>Coming soon</Heading>
        <Text mt={5}>
          Please email
          <Link href="mailto:sales@ridepanda.com" isExternal mx={1}>
            sales@ridepanda.com
          </Link>
          if you would like to discuss enriching your engagement data and help
          get more of your employees out of single occupancy cars.
        </Text>
      </Container>
    </>
  );
}
