import { Box, Flex, Image, Spinner } from "@chakra-ui/react";
import { useWindowSize } from "../../../hooks/useWindowsSize";

type PreviewProps = {
  url: string | undefined;
  isLoading: boolean;
};

export default function Preview({ url, isLoading }: PreviewProps) {
  const windowSize = useWindowSize();

  if (isLoading) {
    return <Spinner />;
  }

  if (!url) {
    return <Box>No preview available</Box>;
  }

  const urlObject = new URL(url);
  const fileExtension = urlObject.pathname.split(".").pop();

  return (
    <Flex h="100%" align="center" justify="center">
      {fileExtension === "pdf" && (
        <embed src={url} width="100%" height={`${windowSize.height}px`} />
      )}
      {fileExtension !== "pdf" && (
        <Box overflow="auto" h="100vh">
          <Image src={url} />
        </Box>
      )}
    </Flex>
  );
}
