import { Flex, Heading, Text } from "@ridepanda/bamboo-ui";
import type React from "react";
import styles from "../Insights.module.css";

type Props = {
  icon: React.ReactNode;
  title: string;
  value: string | null;
  estimated: string | null;
};

export function MetricItem({ icon, title, value, estimated }: Props) {
  return (
    <Flex
      p="24"
      className={styles.Estimateds_List__Item}
      gap="24"
      align="center"
    >
      <Flex
        className={styles.Estimateds_List__Item__Icon}
        align="center"
        justify="center"
      >
        {icon}
      </Flex>

      <Flex direction="col">
        <Text size="14" weight="medium" color="secondary" mb="4">
          {title}
        </Text>
        <Heading size="24" variant="narrow" weight="bold" mb="8">
          {value || "-"}
        </Heading>
        {estimated && (
          <Text size="12" color="tertiary">
            {estimated || "-"}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

export default MetricItem;
