import {
  Input,
  InputGroup,
  InputLeftElement,
  type InputProps,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  Select,
} from "@ridepanda/bamboo-ui";
import { endOfDay, endOfToday, formatISO, startOfDay } from "date-fns";
import { type ReactNode, type Ref, forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { usePaidInvoicesExportMutation } from "../../../api/types-and-hooks";
import CalendarDownIcon from "../../../components/Icons/CalendarDownIcon";
import CalendarUpIcon from "../../../components/Icons/CalendarUpIcon";
import { useApp } from "../../../contexts/AppContext";
import styles from "../Reports.module.css";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = forwardRef(
  (props: InputProps & { leftIcon: ReactNode }, ref: Ref<unknown>) => {
    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          {props.leftIcon}
        </InputLeftElement>
        <Input
          ref={ref}
          border="1px solid #E5DFD9"
          color="#302A27"
          backgroundColor="#FFF"
          {...props}
        />
      </InputGroup>
    );
  },
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function PaidInvoicesExportModal(props: Props) {
  const { isOpen, onClose } = props;
  const { organization } = useApp();
  const toast = useToast();
  const hubs = organization?.historicalHubs || [];
  const [getPaidInvoicesExport, { loading: mutationLoading }] =
    usePaidInvoicesExportMutation();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedHub, setSelectedHub] = useState<string | undefined>();

  const missingDateRange = !startDate || !endDate;

  const onExport = async () => {
    if (missingDateRange) {
      return;
    }

    try {
      const { errors } = await getPaidInvoicesExport({
        variables: {
          organizationId: organization?.id as string,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
          hubId: selectedHub === "all" ? null : selectedHub,
        },
      });

      if (errors) {
        console.error(errors.flatMap((e) => e.message).join("- \n"));
        toast({
          title: "Something went wrong, try again or contact support",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      }
      toast({
        title: `Export of "Rider Invoices" has started`,
        description: "You will receive an email with a download link",
        status: "info",
        isClosable: true,
      });
      onClose();
    } catch (e) {
      console.error(e);
      toast({
        title: "Something went wrong, try again or contact support",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" withoutCloseButton>
      <Heading size="24" className={styles.PaidInvoicesExportModal__Heading}>
        Export rider invoices
      </Heading>

      <Box className={styles.PaidInvoicesExportModal__Divider} />

      <Box style={{ padding: "3rem 1rem" }} mb="48">
        <Flex gap="16" direction="col">
          <Flex gap="12" justify="between">
            <Flex direction="col" gap="4">
              <Text className={styles.PaidInvoicesExportModal__InputLabel}>
                Start date
              </Text>
              <DatePicker
                selected={startDate}
                placeholderText="mm/dd/yyyy"
                onChange={(date) => {
                  if (!date) {
                    setStartDate(undefined);
                    return;
                  }
                  setStartDate(startOfDay(date as Date));
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endOfToday()}
                monthsShown={1}
                customInput={<DateInput leftIcon={<CalendarUpIcon />} />}
              />
            </Flex>
            <Flex direction="col" gap="4">
              <Text className={styles.PaidInvoicesExportModal__InputLabel}>
                End date
              </Text>
              <DatePicker
                selected={endDate}
                placeholderText="mm/dd/yyyy"
                onChange={(date) => {
                  if (!date) {
                    setEndDate(undefined);
                    return;
                  }
                  setEndDate(endOfDay(date as Date));
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={endOfToday()}
                customInput={<DateInput leftIcon={<CalendarDownIcon />} />}
              />
            </Flex>
          </Flex>
          <Flex direction="col" gap="4">
            <Text className={styles.PaidInvoicesExportModal__InputLabel}>
              PandaHub
            </Text>
            <Select
              placeholder="Select"
              value={selectedHub}
              className={styles.PaidInvoicesExportModal__Select}
              fullWidth
              onValueChange={setSelectedHub}
              options={[
                { label: "All Pandahubs", value: "all" },
                ...hubs.map((hub) => ({
                  label: hub.name,
                  value: hub.id,
                })),
              ]}
            />
          </Flex>
        </Flex>
      </Box>

      <Box className={styles.PaidInvoicesExportModal__Divider} />

      <Flex justify="end" gap="4">
        <Button onClick={onClose} variant="outline">
          Close
        </Button>
        <Button
          onClick={onExport}
          isLoading={mutationLoading}
          style={{ background: "#302A27" }}
          disabled={!selectedHub || missingDateRange || mutationLoading}
        >
          Export CSV
        </Button>
      </Flex>
    </Modal>
  );
}
