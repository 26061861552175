import { Icon, type IconProps } from "@chakra-ui/react";

export default function CalendarUpIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 25 24" width="24px" height="24px" fill="none" {...props}>
      <path
        d="M12.5 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V12"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3V7"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3V7"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 11H20"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 22V16"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 19L19 16L16 19"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
