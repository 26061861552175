import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { OAuthProvider } from "firebase/auth";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { loginWithEmail } from "../../api/login";
import { useAuth } from "../../components/AuthProvider";
import MicrosoftIcon from "../../components/Icons/MicrosoftIcon";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopupMS,
} from "../../services/firebase";
import { identifyUserByEmail, trackEvent } from "../../services/heap";

function ErrorMessage() {
  return (
    <Alert status="error" mb={4} maxWidth="md" borderRadius="sm">
      <AlertIcon />
      <AlertDescription fontSize="sm">
        We could not find an account associated with this e-mail. Please email{" "}
        <Link isExternal href="mailto:sales@ridepanda.com" fontWeight="bold">
          sales@ridepanda.com
        </Link>{" "}
        if you need help.
      </AlertDescription>
    </Alert>
  );
}

function EmailSentMessage() {
  return (
    <Alert status="info" maxWidth="md">
      <AlertIcon />
      <AlertDescription fontSize="sm">
        Check your email for the login link. It may take up to several minutes
        to arrive in your inbox.
      </AlertDescription>
    </Alert>
  );
}

export default function Login() {
  const { user, setPendingCredential } = useAuth();
  const [unauthorized, setUnauthorized] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { email } = useParams();
  const [searchParams] = useSearchParams();

  const showMS = searchParams.get("show_ms");

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const formEmail = formData.get("email") as string;
    const response = await loginWithEmail(formEmail);

    setIsLoading(false);

    if (response.error) {
      setUnauthorized(true);
      return;
    }

    setUnauthorized(false);
    setEmailSent(true);
  }

  async function handleLoginLink(loginEmail: string) {
    try {
      identifyUserByEmail(loginEmail);
      trackEvent("Submitted Login", { email: loginEmail });
      await signInWithEmailLink(loginEmail, window.location.href);
      // biome-ignore lint/suspicious/noExplicitAny: TODO: Fix this
    } catch (ex: any) {
      let title = "Something went wrong, try again";
      if (ex instanceof FirebaseError) {
        if (
          ex.code === "auth/invalid-action-code" ||
          ex.code === "auth/missing-email"
        ) {
          title =
            "Login link already used. Please enter your email to generate a new one.";
        }
      }
      toast({
        title,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  }

  const onSignInWithPopupMS = useCallback(async () => {
    try {
      const result = await signInWithPopupMS();
      const resultEmail = result?.user?.email;

      if (!resultEmail) {
        // this is a super weird case coz if the user is not logged in, the result is undefined
        return;
      }

      identifyUserByEmail(resultEmail);
      trackEvent("Submitted Login with Microsoft", {
        email: resultEmail,
      });

      // biome-ignore lint/suspicious/noExplicitAny: No concrete type for `error` is available
    } catch (error: any) {
      if (error.code === "auth/account-exists-with-different-credential") {
        toast({
          title:
            "You already have an account with this email. Please sign in with your existing account. (with phone or email)",
          description:
            "After login we will allow you to link your Microsoft account to your existing account.",
          status: "error",
          duration: 60000,
          isClosable: true,
        });

        const pendingCredential = OAuthProvider.credentialFromError(error);
        setPendingCredential(pendingCredential);

        return;
      }

      toast({
        title: "Something went wrong, try again or contact support",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  }, [setPendingCredential, toast]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isSignInWithEmailLink(window.location.href)) {
      handleLoginLink(atob(email || ""));
    }
  }, []);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Flex direction="column" h="100%" flex={1}>
      <Container>
        <Flex align="center" justify="center" my={8}>
          <Image
            src="/assets/logos/panda-irresponsible-with-helmet.svg"
            w="32px"
            h="32px"
            mr={2}
          />
          <Text fontWeight="bold" fontSize="2xl">
            Ridepanda
          </Text>
        </Flex>

        <Box p={6} mx={{ base: 0, md: 100 }} borderWidth="1px" rounded="md">
          <form onSubmit={handleSubmit}>
            <VStack spacing={8}>
              <Heading textAlign="center" as="h4" fontSize="2xl">
                Sign in to your account
              </Heading>

              {unauthorized && <ErrorMessage />}

              {emailSent && <EmailSentMessage />}

              {!emailSent && (
                <>
                  <FormControl>
                    <FormLabel>Enter your work email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      required
                      variant="filled"
                    />
                  </FormControl>

                  <Flex direction="column" w="full" gap={2}>
                    <Button
                      mt={4}
                      type="submit"
                      variant="block"
                      isLoading={isLoading}
                    >
                      Send my magic login link
                    </Button>
                    {showMS && (
                      <Button
                        leftIcon={<MicrosoftIcon boxSize={4} />}
                        type="button"
                        onClick={onSignInWithPopupMS}
                        w="full"
                        bg="#2f2f2f"
                      >
                        Sign in with Microsoft
                      </Button>
                    )}
                  </Flex>
                </>
              )}
            </VStack>
          </form>
        </Box>
      </Container>
    </Flex>
  );
}
