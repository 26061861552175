import { Flex, Link, ListIcon, ListItem, Tag } from "@chakra-ui/react";
import type { ElementType, ReactNode } from "react";
import type { IconType } from "react-icons/lib";
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import Colors from "../../../theme/foundations/colors";

interface NavLinkProps {
  to: string;
  icon: IconType | ElementType;
  children: ReactNode;
  beta?: boolean;
}

export default function NavLink({
  to,
  icon,
  children,
  beta = false,
}: NavLinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const backgroundColor = match
    ? Colors.customs.coreBgSecondary
    : "transparent";
  const borderColor = match ? Colors.customs.coreBorder : "transparent";

  return (
    <ListItem
      backgroundColor={backgroundColor}
      border="1px solid"
      borderColor={borderColor}
      borderRadius={8}
      px={2}
      py={3}
    >
      <Link
        as={RouterLink}
        to={to}
        color={Colors.customs.coreFgPrimary}
        _hover={{
          textDecoration: "none",
        }}
        fontWeight={500}
      >
        <Flex justify="space-between" alignItems="center">
          <Flex gap={2} alignItems="center">
            <ListIcon
              as={icon}
              boxSize={20}
              w={5}
              h={5}
              margin={0}
              padding={0}
            />
            {children}
          </Flex>
          {beta && <Tag backgroundColor={Colors.customs.sand05}>BETA</Tag>}
        </Flex>
      </Link>
    </ListItem>
  );
}
