import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";
import {
  VehicleCategory,
  useSpokeCheckoutDataCreateMutation,
} from "../../../api/types-and-hooks";
import { Card } from "../../../components/Card";
import FormikEnhanced from "../../../components/FormikEnhanced";
import SquareKeyIcon from "../../../components/Icons/SquareKeyIcon";
import LabeledFormInput from "../../../components/LabeledFormInput";
import { useApp } from "../../../contexts/AppContext";
import { titleCase } from "../../../utils/strings";
import CardPriceForRidepanda from "./CardPriceToRidepanda";
import ModalInvoiceCreation from "./ModalInvoiceCreation";

interface LeaseFormAttributesProps {
  accessoriesPrice: number;
  brandId: string;
  color: string;
  vehicleCategory: VehicleCategory;
  description: string;
  model: string;
  serialNumber: string;
  size: string;
  vehicleRetailPrice: number;
}

const LEASE_FORM_ATTRIBUTES: LeaseFormAttributesProps = {
  accessoriesPrice: 0.0,
  brandId: "",
  color: "",
  vehicleCategory: VehicleCategory.Ebike,
  description: "",
  model: "",
  serialNumber: "",
  size: "",
  vehicleRetailPrice: 0.0,
};

const VALIDATION_SCHEMA = Yup.object({
  accessoriesPrice: Yup.number()
    .required("Accessories price is a required field")
    .min(0, "Accessories price must be at least $0")
    .label("Accesories Price")
    .typeError(({ label, type }) => `${label} must be a ${type}`),
  brandId: Yup.string().required("Vehicle brand is a required field"),
  color: Yup.string().required("Vehicle color is a required field"),
  vehicleCategory: Yup.mixed<VehicleCategory>()
    .oneOf(Object.values(VehicleCategory))
    .required("Vehicle Category is a required field"),
  description: Yup.string().required("Vehicle description is a required field"),
  model: Yup.string().required("Vehicle model is a required field"),
  serialNumber: Yup.string().required(
    "Vehicle serial number is a required field",
  ),
  size: Yup.string().required("Vehicle size is a required field"),
  vehicleRetailPrice: Yup.number()
    .positive("Vehicle price must be a positive value")
    .required("Vehicle price is a required field")
    .label("Vehicle Price")
    .typeError(({ label, type }) => `${label} must be a ${type}`),
});

const inputProps = {
  color: "panda.500",
  backgroundColor: "#F3F2ED",
  height: "3.5rem",
};

const formLabelProps = {
  fontSize: "0.75rem",
  fontWeight: "bold",
};

export default function LeaseCreationForm() {
  const { spoke, currentCustomer } = useApp();
  const brands = spoke?.brands || [];

  const [
    createCheckoutData,
    { data: sessionData, loading: sessionLoading, reset: sessionReset },
  ] = useSpokeCheckoutDataCreateMutation();
  const vehicleCategories = Object.values(VehicleCategory).filter(
    (category) => category !== VehicleCategory.Test,
  );

  const getTotalPrice = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: TODO
    (formik: any): number => {
      if (!spoke) return 0.0;

      const vehiclePrice =
        Number.parseFloat(formik.values.vehicleRetailPrice) || 0.0;

      const accesoriesPrice =
        Number.parseFloat(formik.values.accessoriesPrice) || 0.0;

      return (
        (vehiclePrice + accesoriesPrice) * (1 - spoke.discountPercentage / 100)
      );
    },
    [spoke],
  );

  if (!currentCustomer || !spoke) {
    return <Navigate to="/" replace />;
  }

  const onLeaseFormSubmit = async (
    // biome-ignore lint/suspicious/noExplicitAny: TODO
    _rawValues: any,
    // biome-ignore lint/suspicious/noExplicitAny: TODO
    _actions: any,
    submitAttributes: LeaseFormAttributesProps,
  ) => {
    const {
      accessoriesPrice,
      vehicleRetailPrice: formVehicleRetailPrice,
      ...expectedAttributes
    } = submitAttributes;

    const processedAttributes = {
      ...expectedAttributes,
      accessoriesPriceCents: accessoriesPrice * 100,
      vehicleRetailPriceCents: formVehicleRetailPrice * 100,
    };

    await createCheckoutData({
      variables: {
        sessionAttributes: processedAttributes,
        customerId: currentCustomer.id,
      },
    });
  };

  const onSelectFieldChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    // biome-ignore lint/suspicious/noExplicitAny: TODO
    formContext: any,
    fieldName: string,
  ) => {
    formContext.handleChange(e);
    const field = e.target;

    formContext.setFieldValue(fieldName, field.value);
  };

  const formatVehicleCategory = (category: VehicleCategory) => {
    switch (category) {
      case VehicleCategory.Ebike:
        return "E-Bike";
      case VehicleCategory.Escooter:
        return "E-Scooter";
      case VehicleCategory.PedalBike:
        return "Pedal Bike";
      default:
        return titleCase(category);
    }
  };

  return (
    <Center marginY={5}>
      <VStack spacing={5}>
        <Card width="100%" padding="1.5rem">
          <HStack spacing="1.25rem">
            <Badge
              backgroundColor="#D7E3A4"
              width="6.7rem"
              height="2.5rem"
              padding="0.6rem 0.5rem"
              borderRadius="0.5rem"
            >
              <HStack spacing={0.5} id="customer-checkout-token">
                <SquareKeyIcon size="1.5rem" />
                <Text fontSize="md">{currentCustomer.activationCode}</Text>
              </HStack>
            </Badge>
            <VStack align="start" spacing={0}>
              <Text fontSize="1.25rem" lineHeight="110%" fontWeight={700}>
                {currentCustomer.firstName} {currentCustomer.lastName}
              </Text>
              <Text lineHeight="140%">{currentCustomer.organization.name}</Text>
            </VStack>
          </HStack>
        </Card>

        <Box p={4} padding={0}>
          <FormikEnhanced
            initialValues={LEASE_FORM_ATTRIBUTES}
            enableReinitialize
            validateOnMount
            validationSchema={VALIDATION_SCHEMA}
            nullifyEmptyStrings
            recursiveCleanup
            onSubmit={onLeaseFormSubmit}
          >
            {/* biome-ignore lint/suspicious/noExplicitAny: TODO */}
            {(formik: any) => (
              <form onSubmit={formik.handleSubmit}>
                <Card padding={5} border="2px solid #EAEAEA">
                  <Heading size="lg" fontWeight={650}>
                    Vehicle Details
                  </Heading>
                  <VStack spacing={0} mt={5}>
                    <SimpleGrid
                      templateColumns={{ md: "1fr", lg: "1fr 2fr" }}
                      spacing={3}
                      width="100%"
                    >
                      <LabeledFormInput
                        fieldName="vehicleCategory"
                        label="TYPE"
                        formLabelProps={formLabelProps}
                      >
                        <Select
                          name="vehicleCategory"
                          placeholder="Select"
                          backgroundColor="#F3F2ED"
                          color="panda.500"
                          height="3.5rem"
                          isRequired
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            onSelectFieldChange(e, formik, "vehicleCategory")
                          }
                        >
                          {vehicleCategories.map((vehicleCategory) => {
                            return (
                              <option
                                key={vehicleCategory}
                                value={vehicleCategory}
                              >
                                {formatVehicleCategory(vehicleCategory)}
                              </option>
                            );
                          })}
                        </Select>
                      </LabeledFormInput>
                      <LabeledFormInput
                        fieldName="brandId"
                        label="BRAND"
                        formLabelProps={formLabelProps}
                      >
                        <Select
                          name="brandId"
                          placeholder="Select"
                          backgroundColor="#F3F2ED"
                          color="panda.500"
                          height="3.5rem"
                          isRequired
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            onSelectFieldChange(e, formik, "brandId")
                          }
                        >
                          {brands.map((brand) => {
                            return (
                              <option key={brand.id} value={brand.id}>
                                {brand.name}
                              </option>
                            );
                          })}
                        </Select>
                      </LabeledFormInput>
                    </SimpleGrid>

                    <SimpleGrid
                      templateColumns={{ md: "1fr", lg: "1fr 1fr 1fr" }}
                      spacing={3}
                      width="100%"
                    >
                      <LabeledFormInput
                        fieldName="model"
                        label="MODEL"
                        placeholder="e.g. Voya 3 E+"
                        formLabelProps={formLabelProps}
                        inputProps={inputProps}
                      />
                      <LabeledFormInput
                        fieldName="color"
                        label="COLOR"
                        placeholder="e.g. Asphalt Green"
                        formLabelProps={formLabelProps}
                        inputProps={inputProps}
                      />
                      <LabeledFormInput
                        fieldName="size"
                        label="SIZE"
                        placeholder="e.g. Large XL"
                        formLabelProps={formLabelProps}
                        inputProps={inputProps}
                      />
                    </SimpleGrid>

                    <SimpleGrid
                      templateColumns={{ md: "1fr" }}
                      spacing={3}
                      width="100%"
                    >
                      <LabeledFormInput
                        fieldName="serialNumber"
                        label="SERIAL NUMBER"
                        placeholder="Enter serial number"
                        formLabelProps={formLabelProps}
                        inputProps={inputProps}
                      />
                      <LabeledFormInput
                        fieldName="description"
                        label="DESCRIPTION"
                        placeholder="Additional details such as add-ons and customizations..."
                        formLabelProps={formLabelProps}
                        inputProps={{ ...inputProps, height: "8rem" }}
                        as={Textarea}
                      />
                    </SimpleGrid>
                  </VStack>
                </Card>

                <Card
                  padding={5}
                  border="2px solid #EAEAEA"
                  mt={5}
                  opacity={sessionData ? 0 : 100}
                >
                  <Flex direction="row">
                    <Box>
                      <Heading size="lg" fontWeight={650}>
                        Invoice Charge
                      </Heading>
                      <Text>
                        Enter full retail prices before tax. Ridepanda discount
                        will be applied automatically
                      </Text>
                    </Box>
                    <Spacer />
                  </Flex>

                  <VStack spacing={3} mt={5}>
                    <SimpleGrid
                      templateColumns={{ md: "1fr", lg: "1fr 1fr" }}
                      spacing={3}
                      width="100%"
                    >
                      <LabeledFormInput
                        fieldName="vehicleRetailPrice"
                        label="VEHICLE PRICE"
                        inputType="number"
                        formLabelProps={formLabelProps}
                        inputProps={inputProps}
                      >
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            borderRadius="0.375rem"
                            height="3.5rem"
                            width="3rem"
                            color="panda.500"
                            fontWeight={400}
                          >
                            $
                          </InputLeftElement>

                          <Input
                            {...inputProps}
                            {...formik.getFieldProps("vehicleRetailPrice")}
                          />
                        </InputGroup>
                      </LabeledFormInput>
                      <LabeledFormInput
                        fieldName="accessoriesPrice"
                        label="ACCESSORIES PRICE"
                        inputType="number"
                        formLabelProps={formLabelProps}
                        inputProps={inputProps}
                      >
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            borderRadius="0.375rem"
                            height="3.5rem"
                            width="3rem"
                            color="panda.500"
                            fontWeight={400}
                          >
                            $
                          </InputLeftElement>

                          <Input
                            {...inputProps}
                            {...formik.getFieldProps("accessoriesPrice")}
                          />
                        </InputGroup>
                      </LabeledFormInput>
                    </SimpleGrid>

                    <CardPriceForRidepanda
                      mt={3}
                      totalRetailDiscountedPrice={getTotalPrice(formik)}
                      discountPercentage={spoke?.discountPercentage}
                    />
                  </VStack>
                </Card>

                <Button
                  mt={5}
                  type="submit"
                  isDisabled={!formik.isValid}
                  isLoading={sessionLoading}
                  size="lg"
                >
                  Submit Sale
                </Button>
                {sessionData && !sessionLoading && (
                  <ModalInvoiceCreation
                    onClose={sessionReset}
                    checkoutUrl={
                      sessionData.spokeCheckoutDataCreate?.checkoutUrl
                    }
                    totalRetailDiscountedPrice={getTotalPrice(formik)}
                    customer={currentCustomer}
                  />
                )}
              </form>
            )}
          </FormikEnhanced>
        </Box>
      </VStack>
    </Center>
  );
}
