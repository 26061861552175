import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import SquareBackOutlineIcon from "../../../../components/Icons/SquareBackOutlineIcon";
import Colors from "../../../../theme/foundations/colors";
import Heading from "./Heading";

type Props = { checkoutUrl: string; onClose(): void };

export default function Step1({ checkoutUrl, onClose }: Props) {
  return (
    <>
      <Heading
        step="1 of 2"
        title="Checkout QR code"
        subtitle="Let customer scan this QR code. Checkout will finish automatically once they submit their payment information."
        mb={8}
      />
      <Flex
        bg="customs.mutedBackground"
        rounded="md"
        align="center"
        direction="column"
        p={8}
        gap={3}
      >
        <Box boxSize={200}>
          <QRCode
            value={checkoutUrl}
            viewBox="0 0 200 200"
            style={{ height: "auto", width: "100%" }}
            bgColor={Colors.customs.mutedBackground}
          />
        </Box>

        <Text color="panda.500">Sale checkout QR code</Text>
      </Flex>

      <Flex justifyContent="space-between" mt={8}>
        <Button
          onClick={onClose}
          variant="outline"
          leftIcon={<SquareBackOutlineIcon />}
          size="lg"
        >
          Back
        </Button>
        <Flex direction="row" alignItems="center" gap={2}>
          <Spinner emptyColor="panda.50" color="panda.500" size="sm" />
          <Text>Waiting for customer payment</Text>
        </Flex>
      </Flex>
    </>
  );
}
