import { Box, type BoxProps } from "@ridepanda/bamboo-ui";
import classNames from "classnames";
import type { ReactNode } from "react";
import styles from "./BambooCard.module.css";

type Props = { children: ReactNode; className?: string } & BoxProps;

export function BambooCard({ children, className, ...props }: Props) {
  return (
    <Box className={classNames(styles.BambooCard, className)} {...props}>
      {children}
    </Box>
  );
}

export default BambooCard;
