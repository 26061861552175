import {
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import type { ThemeTypings } from "@chakra-ui/styled-system";
import { createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import type { ElementType } from "react";
import {
  type SpokeInvoiceForTableFragment,
  SpokeInvoiceKind,
  SpokeInvoiceState,
} from "../../../api/types-and-hooks";
import CurrencyText from "../../../components/CurrencyText";
import { ApprovedIcon } from "../../../components/Icons/ApprovedIcon";
import HourglassCircleIcon from "../../../components/Icons/HourglassCircleIcon";
import InfoCircleIcon from "../../../components/Icons/InfoCircleIcon";
import MaintenanceIcon from "../../../components/Icons/MaintenanceIcon";
import MoneyCircleIcon from "../../../components/Icons/MoneyCircleIcon";
import SalesIcon from "../../../components/Icons/SalesIcon";

interface InvoiceNumberColumnProps {
  kind: SpokeInvoiceKind;
  salesOrderNumber: string;
}

function InvoiceNumberColumn({
  kind,
  salesOrderNumber,
}: InvoiceNumberColumnProps) {
  const InvoiceIcon =
    SpokeInvoiceKind.Maintenance === kind.toUpperCase()
      ? MaintenanceIcon
      : SalesIcon;

  return (
    <HStack>
      <InvoiceIcon />
      <Text fontWeight="semibold">{salesOrderNumber}</Text>
    </HStack>
  );
}

interface StatusBadgeProps {
  status: SpokeInvoiceState;
}

interface BadgeCustomization {
  colorScheme: ThemeTypings["colorSchemes"];
  icon: ElementType | undefined;
}

function StatusBadge({ status }: StatusBadgeProps) {
  const customization: BadgeCustomization = {
    colorScheme: "green",
    icon: undefined,
  };

  switch (status.toUpperCase()) {
    case SpokeInvoiceState.NeedsReview:
      customization.colorScheme = "red";
      customization.icon = InfoCircleIcon;
      break;
    case SpokeInvoiceState.Approved:
      customization.colorScheme = "blue";
      customization.icon = ApprovedIcon;
      break;
    case SpokeInvoiceState.Pending:
      customization.colorScheme = "yellow";
      customization.icon = HourglassCircleIcon;
      break;
    case SpokeInvoiceState.Paid:
      customization.colorScheme = "green";
      customization.icon = MoneyCircleIcon;
      break;
    default:
      break;
  }

  return (
    <Tag
      borderRadius={4}
      size="md"
      fontSize="md"
      variant="subtle"
      px={2}
      py={1}
      color="panda.500"
      colorScheme={customization.colorScheme}
    >
      <TagLeftIcon boxSize="16px" as={customization.icon} />
      <TagLabel textTransform="capitalize">
        {status.toLowerCase().split("_").join(" ")}
      </TagLabel>
    </Tag>
  );
}

const columnHelper = createColumnHelper<SpokeInvoiceForTableFragment>();

const columns = [
  columnHelper.accessor("salesOrderNumber", {
    header: () => <span>Invoice number</span>,
    minSize: 250,
    cell: ({
      row: {
        original: { kind, salesOrderNumber },
      },
    }) => (
      <InvoiceNumberColumn
        kind={kind}
        salesOrderNumber={salesOrderNumber || ""}
      />
    ),
  }),
  columnHelper.accessor("state", {
    cell: (props) => <StatusBadge status={props.getValue()} />,
    header: () => <span>Status</span>,
  }),
  columnHelper.accessor(
    (row) => `${row.customer.firstName} ${row.customer.lastName}`,
    {
      id: "customerName",
      header: () => <span>Customer</span>,
    },
  ),
  columnHelper.accessor("createdAt", {
    cell: (props) => {
      const parsed = DateTime.fromISO(props.getValue());
      return (
        <Tooltip hasArrow label={`${parsed.toUTC()} UTC`}>
          {parsed.toLocaleString()}
        </Tooltip>
      );
    },
    header: () => <span>Start Date</span>,
  }),
  columnHelper.display({
    id: "paid_date",
    header: () => <span>Paid Date</span>,
    cell: ({ row: { original: invoice } }) => {
      if (invoice.state !== SpokeInvoiceState.Paid) {
        return "—";
      }

      const parsed = DateTime.fromISO(invoice.stateUpdatedAt);

      return (
        <Tooltip hasArrow label={`${parsed.toUTC()} UTC`}>
          {parsed.toLocaleString()}
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("totalCents", {
    header: () => <span>Total</span>,
    cell: (props) => (
      <CurrencyText
        valueFontWeight="semibold"
        value={props.getValue() / 100.0}
      />
    ),
  }),
];

export default columns;
