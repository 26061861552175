import { Icon } from "@chakra-ui/react";

export function DotIcon({ color }: { color: string }) {
  return (
    <Icon viewBox="0 0 200 200" color={color} w={2.5} h={2.5}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
}

export default DotIcon;
