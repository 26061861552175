import { Icon, type IconProps } from "@chakra-ui/react";

export default function CalendarDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 25 24" width="24px" height="24px" fill="none" {...props}>
      <path
        d="M13 21H6.5C5.96957 21 5.46086 20.7893 5.08579 20.4142C4.71071 20.0391 4.5 19.5304 4.5 19V7C4.5 6.46957 4.71071 5.96086 5.08579 5.58579C5.46086 5.21071 5.96957 5 6.5 5H18.5C19.0304 5 19.5391 5.21071 19.9142 5.58579C20.2893 5.96086 20.5 6.46957 20.5 7V12"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 16V22"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 19L19.5 22L16.5 19"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 3V7"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3V7"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 11H20.5"
        stroke="#8E857D"
        strokeOpacity={1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
