import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap")
      @font-face {
        font-family: "obviously-variable";
        src:
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 400;
        font-stretch: normal;
        ascent-override: 100%;
      }
      @font-face {
        font-family: "obviously-variable";
        src:
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 600;
        font-stretch: normal;
        ascent-override: 100%;
      }
      @font-face {
        font-family: "obviously-variable";
        src:
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 700;
        font-stretch: normal;
        ascent-override: 100%;
      }
      @font-face {
        font-family: "obviously-variable";
        src:
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
          url("https://use.typekit.net/af/94379b/0000000000000000774b907c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 800;
        font-stretch: normal;
        ascent-override: 100%;
      }
    `}
  />
);

export default Fonts;
