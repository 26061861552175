import { Flex, Heading, Text } from "@ridepanda/bamboo-ui";

type Props = { title: string; value: string };

export default function ActiveRidersStat({ title, value }: Props) {
  return (
    <Flex direction="col" gap="4" py="16" px="24">
      <Text size="14" weight="medium" color="secondary">
        {title}
      </Text>
      <Heading size="24" variant="narrow" weight="bold">
        {value}
      </Heading>
    </Flex>
  );
}
