import { Icon, type IconProps } from "@chakra-ui/react";

function BackArrowCircleIcon({
  size,
  ...props
}: IconProps & { size?: number | string | undefined }) {
  return (
    <Icon w={size} h={size} fill="none" viewBox="0 0 20 20" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 13l-3-3 3-3m-1-6c7.2 0 9 1.8 9 9s-1.8 9-9 9-9-1.8-9-9 1.8-9 9-9z"
      />
    </Icon>
  );
}

export default BackArrowCircleIcon;
