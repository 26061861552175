import { Icon, type IconProps } from "@chakra-ui/react";

const MaintenanceIcon = ({
  size,
  ...props
}: IconProps & { size?: string | number | undefined }) => (
  <Icon viewBox="0 0 16 16" w={size} h={size} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.56084 6.49989L9.81084 8.74989L11.5306 10.4696C11.6712 10.6102 11.862 10.6892 12.0608 10.6892C12.2597 10.6892 12.4504 10.6102 12.5911 10.4696L14.5306 8.53014C14.6712 8.38949 14.7502 8.19876 14.7502 7.99989C14.7502 7.80101 14.6712 7.61028 14.5306 7.46964L8.84109 1.78014C8.70045 1.63953 8.50972 1.56055 8.31084 1.56055C8.11197 1.56055 7.92124 1.63953 7.78059 1.78014L5.84109 3.71964C5.70049 3.86028 5.6215 4.05101 5.6215 4.24989C5.6215 4.44876 5.70049 4.63949 5.84109 4.78014L7.56084 6.49989Z"
      fill="#F3C06D"
    />
    <path
      d="M7.56084 6.50184L2.02359 12.0653C1.87686 12.2111 1.7604 12.3844 1.68093 12.5754C1.60146 12.7664 1.56055 12.9711 1.56055 13.178C1.56055 13.3848 1.60146 13.5896 1.68093 13.7805C1.7604 13.9715 1.87686 14.1448 2.02359 14.2906C2.32012 14.5855 2.72136 14.7511 3.13959 14.7511C3.55783 14.7511 3.95906 14.5855 4.25559 14.2906L9.81084 8.75184M12.5911 10.4716L14.5306 8.53209C14.6712 8.39144 14.7502 8.20071 14.7502 8.00184C14.7502 7.80297 14.6712 7.61224 14.5306 7.47159L8.84109 1.78209C8.70045 1.64149 8.50972 1.5625 8.31084 1.5625C8.11197 1.5625 7.92124 1.64149 7.78059 1.78209L5.84109 3.72159C5.70049 3.86224 5.6215 4.05297 5.6215 4.25184C5.6215 4.45071 5.70049 4.64144 5.84109 4.78209L11.5306 10.4716C11.6712 10.6122 11.862 10.6912 12.0608 10.6912C12.2597 10.6912 12.4504 10.6122 12.5911 10.4716Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default MaintenanceIcon;
