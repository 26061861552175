const Colors = {
  customs: {
    mutedBackground: "#F3F2ED",
    mutedBackgroundHover: "#dbdad5",
    coreBorder: "#E3DFDB",
    coreBgSecondary: "#F9F7F4",
    coreFgPrimary: "#2E2A27",
    sand05: "#D0CBC6",
  },
  panda: {
    50: "#adaa9b",
    100: "#999582",
    200: "#706b4f",
    300: "#5c5536",
    400: "#47401d",
    500: "#332B04",
    600: "#292203",
    700: "#1f1a02",
    800: "#141102",
    900: "#0a0901",
  },
  yellow: {
    50: "#FFF6E5",
    100: "#FFE7B8",
    200: "#FFD88A",
    300: "#FFC85C",
    400: "#FFB92E",
    500: "#FFA900",
    600: "#CC8800",
    700: "#996600",
    800: "#664400",
    900: "#332200",
  },
  orange: {
    50: "#FFF2E5",
    100: "#FFDAB8",
    200: "#FFC28A",
    300: "#FFAA5C",
    400: "#FF922E",
    500: "#FF7A00",
    600: "#CC6100",
    700: "#994900",
    800: "#663100",
    900: "#331800",
  },
  red: {
    50: "#FDEBE8",
    100: "#F9C8BE",
    200: "#F5A593",
    300: "#F18269",
    400: "#EE5F3F",
    500: "#EA3C15",
    600: "#BB3011",
    700: "#8C240D",
    800: "#5E1808",
    900: "#2F0C04",
  },
  purple: {
    50: "#F9EBF1",
    100: "#F0C7D8",
    200: "#E6A3BF",
    300: "#DC7FA6",
    400: "#D25B8D",
    500: "#C83774",
    600: "#A02C5D",
    700: "#782145",
    800: "#50162E",
    900: "#280B17",
  },
  blue: {
    50: "#EBF9F9",
    100: "#C7EDF0",
    200: "#A3E2E6",
    300: "#7FD6DC",
    400: "#5BCAD2",
    500: "#37BFC8",
    600: "#2C99A0",
    700: "#217378",
    800: "#164C50",
    900: "#0B2628",
  },
  green: {
    50: "#F0F9EB",
    100: "#D5EFC7",
    200: "#BAE5A3",
    300: "#9EDB80",
    400: "#83D15C",
    500: "#68C738",
    600: "#539F2D",
    700: "#3E7821",
    800: "#2A5016",
    900: "#15280B",
  },
  primary: {
    50: "#f1f8ff",
    100: "#c6e4ff",
    200: "#93cbff",
    300: "#50abff",
    400: "#2697ff",
    500: "#157edf",
    600: "#126bbd",
    700: "#0e5698",
    800: "#0c4880",
    900: "#09345d",
  },
};

export default Colors;
