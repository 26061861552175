import { Icon, type IconProps } from "@chakra-ui/react";

const SquareKeyIcon = ({
  size,
  ...props
}: IconProps & { size?: string | number | undefined }) => (
  <Icon viewBox="0 0 24 24" w={size} h={size} {...props}>
    <path
      d="M12.5 11.5L8.5 15.5L10 17M12 15L10.5 13.5M12 10C12 10.5304 12.2107 11.0391 12.5858 11.4142C12.9609 11.7893 13.4696 12 14 12C14.5304 12 15.0391 11.7893 15.4142 11.4142C15.7893 11.0391 16 10.5304 16 10C16 9.46957 15.7893 8.96086 15.4142 8.58579C15.0391 8.21071 14.5304 8 14 8C13.4696 8 12.9609 8.21071 12.5858 8.58579C12.2107 8.96086 12 9.46957 12 10ZM20 6.667V17.333C20 17.6832 19.931 18.03 19.797 18.3536C19.663 18.6772 19.4665 18.9712 19.2189 19.2189C18.9712 19.4665 18.6772 19.663 18.3536 19.797C18.03 19.931 17.6832 20 17.333 20H6.667C6.31676 20 5.96996 19.931 5.64638 19.797C5.32281 19.663 5.0288 19.4665 4.78115 19.2189C4.53349 18.9712 4.33704 18.6772 4.20301 18.3536C4.06898 18.03 4 17.6832 4 17.333V6.667C4 5.95967 4.28099 5.28131 4.78115 4.78115C5.28131 4.28099 5.95967 4 6.667 4H17.333C17.6832 4 18.03 4.06898 18.3536 4.20301C18.6772 4.33704 18.9712 4.53349 19.2189 4.78115C19.4665 5.0288 19.663 5.32281 19.797 5.64638C19.931 5.96996 20 6.31676 20 6.667Z"
      stroke="#332B04"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </Icon>
);

export default SquareKeyIcon;
