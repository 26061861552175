import { Center, type CenterProps, Spinner } from "@chakra-ui/react";

export const HomeLoader = (props: CenterProps) => {
  return (
    <Center {...props}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="green.500"
        size="xl"
      />
    </Center>
  );
};

export default HomeLoader;
