import { Box, Flex, Text } from "@chakra-ui/react";
import type { ReactNode } from "react";

type Props = { children: ReactNode; title: string; subtitle?: string };

export function Section({ title, subtitle, children }: Props) {
  return (
    <Flex borderBottom="1px solid #EFF2F6" p={8} flexDirection="column">
      <Box mb={8} mt={1}>
        <Text fontSize="xl" fontWeight="bold">
          {title}
        </Text>

        {subtitle && (
          <Text color="#8A92A0" fontSize="sm">
            {subtitle}
          </Text>
        )}
      </Box>

      {children}
    </Flex>
  );
}

export default Section;
