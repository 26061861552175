import {
  Box,
  Container,
  Divider,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useApp } from "../../contexts/AppContext";
import { ActivationCodeForm } from "./components/ActivationCodeForm";

export default function SpokeHome() {
  const { spoke } = useApp();

  return (
    <Container
      maxW="full"
      p={{ base: "0.5rem", md: "1rem", lg: "2rem", xl: "3.5rem" }}
      centerContent
    >
      <VStack
        width="fit-content"
        gap={8}
        px={{ base: "0.5rem", md: "1rem", lg: "2rem", xl: "3.5rem" }}
      >
        <Box w="full" py={4}>
          <Heading fontSize="5xl">{spoke?.name}</Heading>
          <Text fontWeight={500} mt={2}>
            {spoke?.fullAddress}
          </Text>
          <Divider mt={5} />
        </Box>
        <ActivationCodeForm />
      </VStack>
    </Container>
  );
}
