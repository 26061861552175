import {
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { Button, Container, Flex, Heading } from "@ridepanda/bamboo-ui";
import { ChevronDownIcon } from "@ridepanda/icons";
import html2PDF from "jspdf-html2canvas";
import { useCallback, useRef, useState } from "react";
import { TbChartLine, TbFileInvoice } from "react-icons/tb";
import PaidInvoicesExportModal from "../Reports/components/PaidInvoicesExportModal";
import { ActiveRiders } from "./ActiveRiders";
import styles from "./Insights.module.css";
import { PortalActivity } from "./PortalActivity";

enum ExportModalOptions {
  RidersInvoices = "RIDER_INVOICES",
}

export function Insights() {
  const pageRef = useRef(null);

  const downloadHandler = useCallback(() => {
    if (!pageRef.current) return;

    html2PDF(pageRef.current, {
      jsPDF: {
        format: "letter",
      },
      imageType: "image/jpeg",
      output: `insights-${new Date().toLocaleDateString()}.pdf`,
    });
  }, []);
  const [currentOpenExportModal, setCurrentOpenExportModal] =
    useState<ExportModalOptions | null>(null);

  return (
    <Container
      my="64"
      py="32"
      centerContent
      ref={pageRef}
      className={styles.InsightsContainer}
    >
      <Flex justify="between" align="center">
        <Heading variant="narrow" size="40" weight="bold">
          Insights
        </Heading>
        <Popover placement="bottom-end">
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button variant="outline" size="md" className="bg-white">
                  Export Data <ChevronDownIcon />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                p={1.5}
                fontSize="sm"
                borderColor="var(--core-border)"
                borderRadius={12}
                maxW={220}
                fontWeight={500}
                data-html2canvas-ignore="true"
              >
                <List cursor="pointer">
                  <ListItem
                    onClick={() => {
                      onClose();
                      downloadHandler();
                    }}
                    px={2}
                    py={1.5}
                  >
                    <ListIcon as={TbChartLine} w={4} h={4} />
                    Export insights page
                  </ListItem>
                  <ListItem
                    onClick={() => {
                      setCurrentOpenExportModal(
                        ExportModalOptions.RidersInvoices,
                      );
                    }}
                    px={2}
                    py={1.5}
                  >
                    <ListIcon as={TbFileInvoice} w={4} h={4} />
                    Export rider invoices
                  </ListItem>
                </List>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>
      <ActiveRiders />
      <PortalActivity />
      <PaidInvoicesExportModal
        isOpen={currentOpenExportModal === ExportModalOptions.RidersInvoices}
        onClose={() => setCurrentOpenExportModal(null)}
      />
    </Container>
  );
}

export default Insights;
