import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Text,
  type ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TbFileUpload } from "react-icons/tb";

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  onFileChange(file: File): void;
  labelText?: string;
  buttonModeEnabled?: boolean;
};

export default function InputFile({
  onFileChange,
  colorScheme,
  size = "md",
  variant = "filled",
  isDisabled = false,
  isLoading = false,
  labelText = "Select document to upload",
  buttonModeEnabled = false,
  ...props
}: Props & ThemingProps) {
  const [selectedFile, setSelectedFile] = useState<undefined | File>(undefined);
  const inputStyle = useStyleConfig("Input", {
    colorScheme,
    variant,
    size,
    // biome-ignore lint/suspicious/noExplicitAny: TODO
  }) as any;
  const disabledStyles = {
    ...inputStyle.field,
    ...inputStyle.field._disabled,
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        return;
      }

      const firstFile = acceptedFiles[0];
      setSelectedFile(firstFile);
      onFileChange(firstFile);
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    onDrop,
    maxFiles: 1,
    disabled: isDisabled,
  });

  const buttonModeStylings = buttonModeEnabled
    ? {
        background: "none",
        px: 0,
        "&:hover, &:active": {
          background: "none",
        },
      }
    : {};

  return (
    <Box
      {...getRootProps()}
      __css={
        isDisabled ? disabledStyles : { ...inputStyle.field, cursor: "pointer" }
      }
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        userSelect: "none",
        input: {
          display: "none",
        },
        ...buttonModeStylings,
      }}
      {...props}
    >
      {!buttonModeEnabled && (
        <Text color="panda.500" opacity={0.6}>
          {!selectedFile && labelText}
          {selectedFile?.name}
        </Text>
      )}

      {buttonModeEnabled && (
        <Button flex="1" leftIcon={<Icon as={TbFileUpload} boxSize={6} />}>
          {labelText}
        </Button>
      )}

      {!buttonModeEnabled && (
        <Flex align="center">
          {!isLoading && (
            <Icon as={TbFileUpload} color="panda.500" boxSize="24px" />
          )}
          {isLoading && <Spinner color="panda.500" size="sm" />}
        </Flex>
      )}
      <input type="file" {...getInputProps()} />
    </Box>
  );
}
