const Link = {
  baseStyle: {
    fontWeight: "semibold",
    color: "blue.500",
    _hover: {
      color: "blue.600",
    },
  },
  variants: {},
  defaultProps: {},
};

export default Link;
