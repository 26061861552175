import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const helpers = createMultiStyleConfigHelpers(["textarea"]);

const Textarea = helpers.defineMultiStyleConfig({
  baseStyle: {
    backgroundColor: "#FBF8F3",
    borderColor: "#FBF8F3",
    borderRadius: "5px",
    padding: "16px",
    borderWidth: 2,
    height: "7rem",
    color: "panda.500",
    _placeholder: {
      color: "panda.500",
      opacity: 0.6,
    },
    _hover: {
      bg: "customs.mutedBackgroundHover",
    },
    _focusVisible: {
      bg: "customs.mutedBackground",
      borderColor: "panda.500", // TODO: Fix bug with border focus
    },
    _invalid: {
      borderColor: "red.400",
    },
  },
});

export default Textarea;
