import {
  Badge,
  type BadgeProps,
  Stack,
  Text,
  type ThemeTypings,
} from "@chakra-ui/react";
import { SpokeInvoiceState } from "../api/types-and-hooks";

type Props = { state: SpokeInvoiceState };

export function InvoiceStateBadge({ state, ...rest }: Props & BadgeProps) {
  let colorScheme: ThemeTypings["colorSchemes"] | undefined;

  switch (state) {
    case SpokeInvoiceState.Pending:
      colorScheme = "gray";
      break;
    case SpokeInvoiceState.Paid:
      colorScheme = "green";
      break;
    case SpokeInvoiceState.Approved:
      colorScheme = "blue";
      break;
    case SpokeInvoiceState.NeedsReview:
      colorScheme = "red";
      break;
    default:
      break;
  }

  return (
    <Badge
      lineHeight="0.5"
      colorScheme={colorScheme}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Stack display="flex" alignItems="center" justifyItems="center">
        <Text>{state}</Text>
      </Stack>
    </Badge>
  );
}

export default InvoiceStateBadge;
