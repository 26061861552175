declare global {
  interface Window {
    heap?: Heap;
  }
}

// This interface is based on our usage of Heap in other projects, not official in their docs.
interface HeapProperties {
  [key: string]: string | number | boolean | null;
}

// Ref: https://developers.heap.io/reference/client-side-apis-overview#typescript-type-definitions
interface Heap {
  track: (event: string, properties?: HeapProperties) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: HeapProperties) => void;
  addEventProperties: (properties: HeapProperties) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: unknown;
}

export const identifyUserByEmail = (email: string) => {
  window.heap?.identify(email);
};

export const identifyUserByPhone = (phone: string) => {
  window.heap?.identify(phone);
};

export const addUserProperties = (properties: HeapProperties) => {
  window.heap?.addUserProperties(properties);
};

export const trackEvent = (
  eventName: string,
  eventProperties: HeapProperties,
) => {
  window.heap?.track(eventName, eventProperties);
};
