import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  type SpokeInvoice,
  useSpokeInvoiceLazyQuery,
} from "../../../../api/types-and-hooks";
import type { Customer } from "../../../../contexts/AppContext";
import Step1 from "./Step1";
import Step2 from "./Step2";

type Props = {
  onClose(): void;
  checkoutUrl: string;
  totalRetailDiscountedPrice: number;
  customer: Customer;
};

const MAX_RETRIES_IN_MINUTES = 59;
const SECONDS_INTERVAL = 10;

export default function ModalInvoiceCreation({
  customer,
  onClose,
  checkoutUrl,
  totalRetailDiscountedPrice,
}: Props) {
  const toast = useToast();
  const [getSpokeInvoice, { data: invoiceData }] = useSpokeInvoiceLazyQuery({
    variables: {
      customerId: customer.id,
    },
  });

  const [retryCounter, setRetryCounter] = useState(0);
  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (retryCounter > (MAX_RETRIES_IN_MINUTES * 60) / SECONDS_INTERVAL) {
      toast({
        title: "Session Expired",
        description: "Customer needs to use a new QR Code",
        status: "error",
        duration: null,
        isClosable: true,
      });

      onClose();
      return;
    }

    async function checkInvoice() {
      const { data: spokeInvoiceData } = await getSpokeInvoice();

      if (!spokeInvoiceData?.spokeInvoice?.id) {
        setRetryCounter((currentValue) => currentValue + 1);
      }
    }

    timeoutRef.current = window.setTimeout(
      checkInvoice,
      1000 * SECONDS_INTERVAL,
    );

    return function cleanup() {
      clearTimeout(timeoutRef.current);
    };
  }, [retryCounter, getSpokeInvoice, onClose, toast]);

  const customerHasInvoice = !!invoiceData?.spokeInvoice?.id;

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="2xl"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={8}>
          {!customerHasInvoice && (
            <Step1 checkoutUrl={checkoutUrl} onClose={onClose} />
          )}

          {customerHasInvoice && (
            <Step2
              totalRetailDiscountedPrice={totalRetailDiscountedPrice}
              invoice={invoiceData.spokeInvoice as SpokeInvoice}
              salesTaxEnabled={
                invoiceData?.spokeInvoice?.spoke?.salesTaxEnabled || false
              }
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
