import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import axios, { type AxiosProgressEvent } from "axios";
import {
  type Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useInvoiceDocUrlCreateMutation } from "../../api/types-and-hooks";
import InputFile from "../InputFile";

export type FileUploaderRef = { uploadFile(): void };

type RemoteFile = {
  url: string;
};

type Props = {
  onUploadComplete(file: RemoteFile): unknown;
  onUploadStateChange?(state: boolean): void;
  automaticUpload?: boolean;
  onUploadProgress?(progress: AxiosProgressEvent, file: File): void;
  labelText?: string;
  buttonModeEnabled?: boolean;
};

function FileUploader(
  {
    automaticUpload = false,
    onUploadComplete,
    onUploadProgress,
    onUploadStateChange,
    buttonModeEnabled = false,
    labelText,
  }: Props,
  ref: Ref<FileUploaderRef> | undefined,
) {
  const [
    createFileUploadUrl,
    { data: fileUploadData, loading: fileUrlLoading },
  ] = useInvoiceDocUrlCreateMutation();
  const [currentFile, setCurrentFile] = useState<undefined | File>(undefined);
  const [displayErrorAlert, setDisplayErrorAlert] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);

  const uploadFile = useCallback(() => {
    if (
      !fileUploadData ||
      !fileUploadData.fileUploadUrlCreate ||
      !currentFile
    ) {
      return;
    }

    setIsUploading(true);

    const { uploadUrl, publicUrl } = fileUploadData.fileUploadUrlCreate;

    axios
      .put(uploadUrl, currentFile, {
        headers: {
          "Content-Type": currentFile.type,
        },
        onUploadProgress: (progress) => {
          if (onUploadProgress) {
            onUploadProgress(progress, currentFile);
          }
        },
      })
      .then(() =>
        onUploadComplete(
          Object.assign(currentFile, {
            url: publicUrl,
          }),
        ),
      )
      .catch((error) => {
        setDisplayErrorAlert(true);
        console.error(error);
      })
      .finally(() => setIsUploading(false));
  }, [currentFile, fileUploadData, onUploadComplete, onUploadProgress]);

  useEffect(() => {
    if (!fileUploadData || !automaticUpload || hasUploaded) {
      return;
    }

    uploadFile();

    setHasUploaded(true);
  }, [fileUploadData, hasUploaded, uploadFile, automaticUpload]);

  useEffect(() => {
    if (onUploadStateChange) {
      onUploadStateChange(fileUrlLoading || isUploading);
    }
  }, [fileUrlLoading, isUploading, onUploadStateChange]);

  useImperativeHandle(
    ref,
    () => {
      return {
        uploadFile,
      };
    },
    [uploadFile],
  );

  return (
    <div>
      {!fileUrlLoading && displayErrorAlert && (
        <Alert status="error" mb={3}>
          <AlertIcon />
          <AlertDescription>
            Something happened when uploading the file, try again.
          </AlertDescription>
        </Alert>
      )}

      <InputFile
        isLoading={fileUrlLoading || isUploading}
        isDisabled={fileUrlLoading || isUploading}
        labelText={labelText}
        buttonModeEnabled={buttonModeEnabled}
        onFileChange={async (file: File) => {
          setDisplayErrorAlert(false);
          setCurrentFile(file);
          setHasUploaded(false);

          createFileUploadUrl({
            variables: {
              prefix: file.name,
              mimeType: file.type,
              fileSize: file.size,
            },
          });
        }}
      />
    </div>
  );
}

export default forwardRef(FileUploader);
