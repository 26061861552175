import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import { useAuth } from "../AuthProvider";
import Sidebar, { SIDEBAR_WIDTH } from "./Sidebar/index";
import "@ridepanda/bamboo-ui/styles.css";

export default function Layout() {
  const { authStateLoading, user } = useAuth();

  if (!authStateLoading && !user) {
    return <Outlet />;
  }

  return (
    <AppContext>
      <Flex direction="column" h="100%" flex={1}>
        <Flex flex={1}>
          <Sidebar />
          <Flex
            direction="column"
            flex={1}
            marginLeft={SIDEBAR_WIDTH}
            css={{
              "@media print": {
                marginLeft: 0,
                width: "100%",
              },
            }}
          >
            <Box>
              <Outlet />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </AppContext>
  );
}
