import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../../AuthProvider";

export default function UserProfile() {
  const { user, signOut } = useAuth();

  return (
    <Flex padding={2}>
      <Menu>
        <MenuButton
          as={Button}
          rounded="full"
          variant="unstyled"
          cursor="pointer"
          minW={0}
        >
          <Flex
            boxSize="48px"
            bg="primary.500"
            rounded="full"
            justify="center"
            align="center"
          >
            <Text color="white" textTransform="uppercase">
              {user?.email?.substring(0, 2)}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList padding={0} minW="max-content">
          <MenuItem
            paddingRight={4}
            icon={<FaSignOutAlt size={14} />}
            onClick={signOut}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
