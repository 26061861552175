import type { OAuthCredential, User } from "firebase/auth";
import React, { type ReactNode, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { onAuthStateChanged, signOut } from "../../services/firebase";
import ModalLinkAccounts from "../ModalLinkAccounts";

type Props = {
  children: ReactNode;
};

interface AuthContextType {
  user: User | null;
  authStateLoading: boolean;
  signOut(): void;
  setPendingCredential(credential: OAuthCredential | null): void;
}

// biome-ignore lint/style/noNonNullAssertion: TODO: Fix this
const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null);
  const [pendingCredential, setPendingCredential, removePendingCredential] =
    useLocalStorage<OAuthCredential | null>("pending-credential", null);
  const [authStateLoading, setAuthStateLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged((responseUser) => {
      setAuthStateLoading(false);
      setUser(responseUser);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    authStateLoading,
    signOut,
    setPendingCredential,
  };

  return (
    <AuthContext.Provider value={value}>
      {!authStateLoading && children}

      {user && pendingCredential && (
        <ModalLinkAccounts
          currentUser={user}
          pendingCredential={pendingCredential}
          removePendingCredential={removePendingCredential}
        />
      )}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}
