import { Icon, type IconProps } from "@chakra-ui/react";

export function ApprovedIcon({ ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 21 21" {...props}>
      <path
        d="M6.99997 9.99997L8.99997 12L13 7.99997M2.99997 5.19997C2.99997 4.6165 3.23176 4.05692 3.64434 3.64434C4.05692 3.23176 4.6165 2.99997 5.19997 2.99997H6.19997C6.78087 2.99964 7.33806 2.76957 7.74997 2.35997L8.44997 1.65997C8.65442 1.45437 8.89749 1.29121 9.16521 1.17988C9.43294 1.06855 9.72003 1.01123 10.01 1.01123C10.2999 1.01123 10.587 1.06855 10.8547 1.17988C11.1225 1.29121 11.3655 1.45437 11.57 1.65997L12.27 2.35997C12.682 2.76997 13.24 2.99997 13.82 2.99997H14.82C15.4034 2.99997 15.963 3.23176 16.3756 3.64434C16.7882 4.05692 17.02 4.6165 17.02 5.19997V6.19997C17.02 6.77997 17.25 7.33797 17.66 7.74997L18.36 8.44997C18.5656 8.65442 18.7287 8.89749 18.8401 9.16521C18.9514 9.43294 19.0087 9.72003 19.0087 10.01C19.0087 10.2999 18.9514 10.587 18.8401 10.8547C18.7287 11.1225 18.5656 11.3655 18.36 11.57L17.66 12.27C17.2504 12.6819 17.0203 13.2391 17.02 13.82V14.82C17.02 15.4034 16.7882 15.963 16.3756 16.3756C15.963 16.7882 15.4034 17.02 14.82 17.02H13.82C13.2391 17.0203 12.6819 17.2504 12.27 17.66L11.57 18.36C11.3655 18.5656 11.1225 18.7287 10.8547 18.8401C10.587 18.9514 10.2999 19.0087 10.01 19.0087C9.72003 19.0087 9.43294 18.9514 9.16521 18.8401C8.89749 18.7287 8.65442 18.5656 8.44997 18.36L7.74997 17.66C7.33806 17.2504 6.78087 17.0203 6.19997 17.02H5.19997C4.6165 17.02 4.05692 16.7882 3.64434 16.3756C3.23176 15.963 2.99997 15.4034 2.99997 14.82V13.82C2.99964 13.2391 2.76957 12.6819 2.35997 12.27L1.65997 11.57C1.45437 11.3655 1.29121 11.1225 1.17988 10.8547C1.06855 10.587 1.01123 10.2999 1.01123 10.01C1.01123 9.72003 1.06855 9.43294 1.17988 9.16521C1.29121 8.89749 1.45437 8.65442 1.65997 8.44997L2.35997 7.74997C2.76957 7.33806 2.99964 6.78087 2.99997 6.19997V5.19997Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
}

export default ApprovedIcon;
