import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { type AuthCredential, OAuthCredential, type User } from "firebase/auth";
import { useState } from "react";
import { linkWithCredential } from "../../services/firebase";

type Props = {
  currentUser: User;
  pendingCredential: AuthCredential;
  removePendingCredential(): void;
};

export default function ModalLinkAccounts(props: Props) {
  const { currentUser, pendingCredential, removePendingCredential } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onClose = async () => {
    removePendingCredential();
  };

  const onLinkAccounts = async () => {
    setIsLoading(true);

    const parsedCredential = OAuthCredential.fromJSON(pendingCredential);

    if (!parsedCredential) {
      setIsLoading(false);

      alert("Invalid credential");
      return;
    }

    await linkWithCredential(currentUser, parsedCredential);

    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Link Accounts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Do you want to link your Microsoft account to your logged in
            account?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button bg="#332B04" onClick={onLinkAccounts} isLoading={isLoading}>
            Link Accounts
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
