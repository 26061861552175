import { initializeApp } from "firebase/app";
import {
  type ActionCodeSettings,
  type NextOrObserver,
  type OAuthCredential,
  OAuthProvider,
  type User,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  linkWithCredential as firebaseLinkWithCredential,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signOut as firebaseSignOut,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { getFirebaseConfig } from "./configs";

const firebaseConfig = getFirebaseConfig();

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Auth
export const auth = getAuth(firebaseApp);

export const onAuthStateChanged = (handler: NextOrObserver<User>) =>
  firebaseOnAuthStateChanged(auth, handler);

export const sendSignInLinkToEmail = (
  email: string,
  settings: ActionCodeSettings,
) => firebaseSendSignInLinkToEmail(auth, email, settings);

export const signInWithEmailLink = (
  email: string,
  emailLink: string | undefined,
) => firebaseSignInWithEmailLink(auth, email, emailLink);

export const isSignInWithEmailLink = (emailLink: string) =>
  firebaseIsSignInWithEmailLink(auth, emailLink);

export const signOut = async () => firebaseSignOut(auth);

const providerMicrosoft = new OAuthProvider("microsoft.com");

providerMicrosoft.setCustomParameters({
  prompt: "login",
});

export const signInWithPopupMS = () => signInWithPopup(auth, providerMicrosoft);
export const linkWithCredential = (user: User, credential: OAuthCredential) =>
  firebaseLinkWithCredential(user, credential);
