import {
  theme as base,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";

import Button from "./components/button";
import Input from "./components/input";
import Link from "./components/link";
import Textarea from "./components/textarea";
import Colors from "./foundations/colors";

export default extendTheme(
  {
    fonts: {
      heading: `'obviously-variable', ${base.fonts?.heading}`,
      body: `'Inter', ${base.fonts?.body}`,
    },
    colors: Colors,
    components: {
      Button,
      Input,
      Link,
      Textarea,
    },
    styles: {
      global: () => ({
        body: {
          bg: "#FDFBFA",
        },
      }),
    },
  },
  withDefaultColorScheme({
    colorScheme: "panda",
    components: ["Button"],
  }),
);
