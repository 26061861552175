import {
  type AlertStatus,
  Button,
  Card,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  type Customer,
  useValidateCustomerActivationCodeLazyQuery,
} from "../../../api/types-and-hooks";
import { useApp } from "../../../contexts/AppContext";
import { validActivationCodeFormat } from "../../../services/activationCodeValidation";
import { trackEvent } from "../../../services/heap";

export function ActivationCodeForm() {
  const { setCurrentCustomer } = useApp();
  const [activationCode, setActivationCode] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [activationCodeValidation] =
    useValidateCustomerActivationCodeLazyQuery();

  const showToastMessage = (
    messageStatus: AlertStatus,
    messageContent: string,
  ): void => {
    setIsLoading(false);
    toast({
      title: messageContent,
      status: messageStatus,
      duration: 5000,
      position: "top-right",
      isClosable: true,
    });
  };

  const onActivationCodeSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    trackEvent("Submitted Activation Code", { activationCode });
    setCurrentCustomer(undefined);
    setIsLoading(true);

    const { data: customerValidationData } = await activationCodeValidation({
      variables: { activationCode },
    });

    if (!customerValidationData) {
      setError(true);
      showToastMessage("error", "Something went wrong. Please try again.");
      return;
    }

    const {
      valid,
      error: errorMessage,
      customer,
    } = customerValidationData.validateCustomerActivationCode;

    if (!valid) {
      setError(true);
      showToastMessage("error", errorMessage || "Something went wrong.");
      return;
    }

    if (!customer) {
      return;
    }

    setCurrentCustomer(customer as Customer);
    showToastMessage(
      "success",
      `Customer with name ${customer?.firstName} retrieved`,
    );
    navigate("lease_form");
  };

  return (
    <Card
      display="flex"
      direction={{ base: "column", xl: "row" }}
      maxH={{ base: "unset", xl: 360 }}
      alignItems="center"
      overflow="hidden"
    >
      <Image src="/assets/activation-code.png" maxW={{ base: 640, xl: 480 }} />
      <Flex
        direction="column"
        maxW="40rem"
        p={{ base: 0, sm: 12 }}
        justifyContent="space-between"
        gap={4}
      >
        <Flex gap={4} direction="column">
          <Heading size="lg">
            Start every visit by checking customer&apos;s activation code
          </Heading>
          <Text>
            Please make sure to verify customer&apos;s{" "}
            <b>Ridepanda Activation Code</b> as the{" "}
            <b>first step for each customer visit</b>. This ensures that the
            customer is eligible for the vehicle lease and will enable you to
            proceed with the sale.
          </Text>
        </Flex>
        <Flex gap={3} direction={{ base: "column", md: "row" }}>
          <FormControl isInvalid={error} flexGrow={1}>
            <Input
              required
              size="lg"
              value={activationCode}
              maxLength={6}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setActivationCode(e.target.value.toUpperCase())
              }
              placeholder="Enter activation code"
              border="1px"
              borderColor="#E3DFDB"
              variant="outline"
            />
          </FormControl>
          <Button
            size="lg"
            onClick={onActivationCodeSubmit}
            disabled={!validActivationCodeFormat(activationCode)}
            isLoading={isLoading && validActivationCodeFormat(activationCode)}
            loadingText="Verifying"
            minWidth="unset"
            type="submit"
            bg="#332B04"
          >
            Verify code
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}

export default ActivationCodeForm;
