import { Icon, type IconProps } from "@chakra-ui/react";

const SalesIcon = ({
  size,
  ...props
}: IconProps & { size?: number | string | undefined }) => (
  <Icon viewBox="0 0 15 15" w={size} h={size} {...props}>
    <path
      d="M1 3.25V6.14425C1 6.547 1.15975 6.93325 1.44475 7.21825L7.53175 13.3053C7.67278 13.4463 7.84022 13.5582 8.0245 13.6345C8.20877 13.7109 8.40629 13.7502 8.60575 13.7502C8.80521 13.7502 9.00273 13.7109 9.187 13.6345C9.37128 13.5582 9.53872 13.4463 9.67975 13.3053L13.3053 9.67975C13.4463 9.53872 13.5582 9.37128 13.6345 9.187C13.7109 9.00273 13.7502 8.80521 13.7502 8.60575C13.7502 8.40629 13.7109 8.20877 13.6345 8.0245C13.5582 7.84022 13.4463 7.67278 13.3053 7.53175L7.2175 1.44475C6.93283 1.16012 6.5468 1.00016 6.14425 1H3.25C2.65326 1 2.08097 1.23705 1.65901 1.65901C1.23705 2.08097 1 2.65326 1 3.25Z"
      fill="#B3E79A"
    />
    <path
      d="M3.625 4.375C3.625 4.57391 3.70402 4.76468 3.84467 4.90533C3.98532 5.04598 4.17609 5.125 4.375 5.125C4.57391 5.125 4.76468 5.04598 4.90533 4.90533C5.04598 4.76468 5.125 4.57391 5.125 4.375C5.125 4.17609 5.04598 3.98532 4.90533 3.84467C4.76468 3.70402 4.57391 3.625 4.375 3.625C4.17609 3.625 3.98532 3.70402 3.84467 3.84467C3.70402 3.98532 3.625 4.17609 3.625 4.375ZM1 3.25V6.14425C1 6.547 1.15975 6.93325 1.44475 7.21825L7.53175 13.3052C7.67278 13.4463 7.84022 13.5582 8.0245 13.6345C8.20877 13.7109 8.40629 13.7502 8.60575 13.7502C8.80521 13.7502 9.00273 13.7109 9.187 13.6345C9.37128 13.5582 9.53872 13.4463 9.67975 13.3052L13.3052 9.67975C13.4463 9.53872 13.5582 9.37128 13.6345 9.187C13.7109 9.00273 13.7502 8.80521 13.7502 8.60575C13.7502 8.40629 13.7109 8.20877 13.6345 8.0245C13.5582 7.84022 13.4463 7.67278 13.3052 7.53175L7.2175 1.44475C6.93283 1.16012 6.5468 1.00016 6.14425 1H3.25C2.65326 1 2.08097 1.23705 1.65901 1.65901C1.23705 2.08097 1 2.65326 1 3.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
);

export default SalesIcon;
