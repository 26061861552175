interface Props extends Intl.NumberFormatOptions {
  value: number;
}

export const formatter = (options?: Intl.NumberFormatOptions | undefined) => {
  // ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  return new Intl.NumberFormat(undefined, options);
};

export const NumberFormat = ({ value, ...rest }: Props) => {
  return <>{formatter(rest).format(value)}</>;
};

export default NumberFormat;
