import React, { type ReactNode, useEffect, useState } from "react";
import {
  type GetOrganizationQuery,
  type GetSpokeQuery,
  UserAccessLevel,
  type ValidateCustomerActivationCodeQuery,
  useGetCurrentAccessLevelsLazyQuery,
  useGetOrganizationLazyQuery,
  useGetSpokeLazyQuery,
} from "../api/types-and-hooks";
import { useAuth } from "../components/AuthProvider";

type Props = {
  children: ReactNode;
};

export type Spoke = NonNullable<GetSpokeQuery["spoke"]>;
export type Organization = NonNullable<GetOrganizationQuery["organization"]>;
export type Customer = NonNullable<
  ValidateCustomerActivationCodeQuery["validateCustomerActivationCode"]["customer"]
>;

interface AppContextType {
  organization?: Organization;
  spoke?: Spoke;
  currentAccessLevels: UserAccessLevel[];
  currentCustomer?: Customer | undefined | null;
  setCurrentCustomer: (newCustomer: Customer | undefined | null) => void;
}

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default function AppProvider({ children }: Props) {
  const [fetchOrganization, { data: organizationData }] =
    useGetOrganizationLazyQuery({
      fetchPolicy: "no-cache",
    });
  const [fetchSpoke, { data: spokeData }] = useGetSpokeLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [fetchCurrentAccessLevels, { data: currentAccessLevelsData }] =
    useGetCurrentAccessLevelsLazyQuery({
      fetchPolicy: "no-cache",
    });
  const { user: firebaseUser } = useAuth();
  const organization = organizationData?.organization as Organization;
  const spoke = spokeData?.spoke as Spoke;
  const currentAccessLevels =
    currentAccessLevelsData?.currentAccessLevels as UserAccessLevel[];
  const [currentCustomer, setCurrentCustomer] = useState<
    Customer | undefined | null
  >();

  const value = {
    currentAccessLevels,
    spoke,
    organization,
    currentCustomer,
    setCurrentCustomer,
  };

  useEffect(() => {
    if (!firebaseUser) {
      return;
    }
    fetchCurrentAccessLevels();
  }, [firebaseUser, fetchCurrentAccessLevels]);

  useEffect(() => {
    if (!currentAccessLevels) {
      return;
    }

    if (currentAccessLevels.includes(UserAccessLevel.OrganizationAdmin)) {
      fetchOrganization();
    }

    if (currentAccessLevels.includes(UserAccessLevel.SpokeAdmin)) {
      fetchSpoke();
    }
  }, [currentAccessLevels, fetchOrganization, fetchSpoke]);

  return (
    <AppContext.Provider value={value}>
      {currentAccessLevels && children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within a AppContext");
  }

  return context;
}
