import { Box, type BoxProps } from "@chakra-ui/react";

type Props = {
  innerProps?: BoxProps;
} & BoxProps;

export const Card = ({ innerProps, ...props }: Props) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="sm"
      bg="white"
      {...props}
    >
      <Box p="0" {...innerProps}>
        {props.children}
      </Box>
    </Box>
  );
};

export default Card;
