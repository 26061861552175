import { Text } from "@chakra-ui/react";
import { formatter } from "../NumberFormat";

interface Props {
  value: number;
  valueFontWeight?: string;
  suffix?: string;
  suffixFontWeight?: string;
  currency?: string;
  currencyDisplay?: string;
  decimals?: number;
}

const CurrencyText = ({
  value,
  valueFontWeight = "",
  suffix = "",
  suffixFontWeight = "",
  currency = "USD",
  currencyDisplay = "symbol",
  decimals = 0,
}: Props) => {
  const currencyFormat = formatter({
    style: "currency",
    currency,
    currencyDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return (
    <>
      <Text as="span" fontWeight={valueFontWeight}>
        {currencyFormat.format(value)}
      </Text>
      <Text as="span" fontWeight={suffixFontWeight}>
        {suffix}
      </Text>
    </>
  );
};

export default CurrencyText;
