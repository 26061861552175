import { getBackendConfig } from "../services/configs";

const loginWithEmail = async (email: string) => {
  const url = `${getBackendConfig()}/partner_login`;

  const response = await fetch(url, {
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ email }),
  });

  const data = await response.json();

  return data;
};

export { loginWithEmail };
