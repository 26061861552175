import {
  type As,
  FormControl,
  type FormControlProps,
  FormErrorMessage,
  FormLabel,
  type FormLabelProps,
  Input,
  type InputProps,
  type TextareaProps,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import type { ReactNode } from "react";

interface LabelFormInput {
  fieldName: string;
  label: string;
  placeholder?: string;
  inputType?: string;
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  inputProps?: InputProps | TextareaProps;
  children?: ReactNode;
  as?: As;
}

function LabeledFormInput(props: LabelFormInput) {
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  const formik = useFormikContext() as any;
  const {
    children,
    fieldName,
    label,
    placeholder,
    inputType = "text",
    formControlProps,
    formLabelProps,
    inputProps,
    as,
  } = props;

  const Comp = as || Input;

  return (
    <FormControl
      isInvalid={!!(formik.errors[fieldName] && formik.touched[fieldName])}
      mb={3}
      {...formControlProps}
    >
      <FormLabel
        textTransform="uppercase"
        fontSize={12}
        fontWeight={700}
        htmlFor={fieldName}
        {...formLabelProps}
      >
        {label}
      </FormLabel>
      {children || (
        <Comp
          as={as}
          id={fieldName}
          type={inputType}
          placeholder={placeholder}
          {...inputProps}
          {...formik.getFieldProps(fieldName)}
        />
      )}
      <FormErrorMessage>{formik.errors[fieldName]}</FormErrorMessage>
    </FormControl>
  );
}

export default LabeledFormInput;
