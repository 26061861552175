import { Box, Button, VStack, useToast } from "@chakra-ui/react";

import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  type SpokeInvoice,
  useSpokeInvoiceUpdateMutation,
} from "../../../../api/types-and-hooks";
import FileUploader from "../../../../components/FileUploader";
import LabeledFormInput from "../../../../components/LabeledFormInput";
import { useApp } from "../../../../contexts/AppContext";
import CardPriceForRidepanda from "../CardPriceToRidepanda";
import Heading from "./Heading";

type Props = {
  invoice: SpokeInvoice;
  salesTaxEnabled: boolean;
  totalRetailDiscountedPrice: number;
};

type FormValues = { invoiceNumber: string; invoiceUrl: string };

const VALIDATION_SCHEMA = Yup.object({
  invoiceNumber: Yup.string().required("Invoice Number is a required field"),
  invoiceUrl: Yup.string().required("Invoice File is a required field"),
});

export default function Step2({
  invoice,
  totalRetailDiscountedPrice,
  salesTaxEnabled,
}: Props) {
  const navigate = useNavigate();
  const { spoke } = useApp();
  const [spokeInvoiceUpdate, { loading, error }] =
    useSpokeInvoiceUpdateMutation();
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const onSubmit = async (values: FormValues) => {
    try {
      await spokeInvoiceUpdate({
        variables: {
          invoiceId: invoice.id,
          invoiceDocUrl: values.invoiceUrl,
          salesOrderNumber: values.invoiceNumber,
        },
      });

      toast({
        title: "Invoice saved successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/spoke_invoices");
    } catch (_e) {
      if (error) {
        toast({
          title: "Something went wrong",
          description: error.message,
          status: "error",
          isClosable: true,
        });

        return;
      }
    }
  };

  const submitInvoiceString =
    "Submit your invoice to Ridepanda to complete the sale";
  const subtitle =
    submitInvoiceString +
    (!salesTaxEnabled ? ". Please do NOT include Sales Tax." : "");

  return (
    <>
      <Heading
        step="2 of 2"
        title="Checkout Completed"
        subtitle={subtitle}
        mb={8}
      />

      <Formik
        initialValues={{ invoiceNumber: "", invoiceUrl: "" }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={onSubmit}
        validateOnMount
      >
        {(formik) => (
          <Box w="100%">
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={8} align="start">
                <CardPriceForRidepanda
                  totalRetailDiscountedPrice={totalRetailDiscountedPrice}
                  discountPercentage={spoke?.discountPercentage}
                />
                <VStack spacing={4} w="100%">
                  <LabeledFormInput
                    fieldName="invoiceNumber"
                    label="Invoice Number"
                    placeholder="Enter invoice number"
                    inputProps={{ size: "lg" }}
                  />

                  <LabeledFormInput
                    fieldName="invoiceDocument"
                    label="Invoice Document"
                    placeholder="Enter invoice number"
                    inputProps={{ size: "lg" }}
                    inputType="file"
                  >
                    <FileUploader
                      automaticUpload
                      onUploadStateChange={setIsUploading}
                      onUploadComplete={(file) => {
                        formik.getFieldHelpers("invoiceUrl").setValue(file.url);
                      }}
                    />
                  </LabeledFormInput>
                </VStack>
                <Button
                  type="submit"
                  size="lg"
                  isDisabled={!formik.isValid || isUploading}
                  isLoading={loading}
                >
                  Complete Sale
                </Button>
              </VStack>
            </form>
          </Box>
        )}
      </Formik>
    </>
  );
}
