import { Icon, type IconProps } from "@chakra-ui/react";

function ForwardArrowCircleIcon({
  size,
  ...props
}: IconProps & { size?: string | number | undefined }) {
  return (
    <Icon w={size} h={size} fill="none" viewBox="0 0 20 20" {...props}>
      <path
        d="M9 7L12 10L9 13M10 1C17.2 1 19 2.8 19 10C19 17.2 17.2 19 10 19C2.8 19 1 17.2 1 10C1 2.8 2.8 1 10 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ForwardArrowCircleIcon;
