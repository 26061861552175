import { Flex, Heading, Text } from "@ridepanda/bamboo-ui";
import { TbArrowDownRight, TbArrowUpRight } from "react-icons/tb";
import BambooBadge from "../../../components/BambooBadge";

interface ActivityItemProps {
  title: string;
  value?: number;
  previousValue?: number;
}

export function ActivityItem({
  title,
  value,
  previousValue,
}: ActivityItemProps) {
  const formattedValue = value ? value.toLocaleString() : "-";
  const diff = value && previousValue ? value - previousValue : 0;

  return (
    <Flex direction="col" p="24" gap="8">
      <Text size="14">{title}</Text>
      <Flex gap="10">
        <Heading size="40" variant="narrow" weight="bold">
          {formattedValue}
        </Heading>

        {diff !== 0 && (
          <Flex align="end" py="6">
            <BambooBadge type={diff > 0 ? "success" : "error"}>
              {diff > 0 && <TbArrowUpRight />}
              {diff < 0 && <TbArrowDownRight />}
              <Text size="14" weight="medium">
                {Math.abs(diff).toLocaleString()}
              </Text>
            </BambooBadge>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default ActivityItem;
