import { Box, Heading } from "@chakra-ui/react";
import type React from "react";

type Props = { children?: React.ReactNode; title?: string };

export default function Header({ children, title }: Props) {
  return (
    <Box
      h={65}
      padding={5}
      mb={5}
      borderBottomColor="#eaeaea"
      borderBottomWidth={1}
    >
      {title && (
        <Heading as="h2" fontSize="2xl" textTransform="capitalize">
          {title}
        </Heading>
      )}

      {children}
    </Box>
  );
}
