import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import {
  CurrencyCode,
  SpokeInvoiceState,
  useSpokeInvoiceQuery,
  useSpokeInvoiceUpdateMutation,
} from "../../../api/types-and-hooks";
import FileUploader from "../../../components/FileUploader";
import { InvoiceStateBadge } from "../../../components/InvoiceStateBadge";
import { HomeLoader } from "../../../components/Loaders";
import { formatMoney } from "../../../utils/formatMoney";
import Preview from "./Preview";
import { Section } from "./Section";

export function SpokeInvoiceDetails() {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const toast = useToast();
  const [spokeInvoiceUpdate, { error: mutationError }] =
    useSpokeInvoiceUpdateMutation();

  const { data, loading, refetch, error } = useSpokeInvoiceQuery({
    variables: { invoiceId: id },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <HomeLoader />;
  }

  if (error) {
    toast({
      title: "Something went wrong",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

    console.error(error);

    return null;
  }

  const invoice = data?.spokeInvoice;

  if (!invoice) {
    toast({
      title: "Invoice not found",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

    return null;
  }

  const monthlyTotalAmount = (
    (invoice.lease?.monthlyTotalPriceCents || 0) / 100.0
  ).toFixed(2);

  const buyoutAmount = (invoice.lease?.buyoutPriceCents || 0) / 100.0;

  const handleUploadComplete = async (updatedUrl: string) => {
    await spokeInvoiceUpdate({
      variables: {
        invoiceId: invoice.id,
        invoiceDocUrl: updatedUrl,
        salesOrderNumber: invoice?.salesOrderNumber || "",
      },
    });

    if (mutationError) {
      toast({
        title: "Something went wrong - request error(s):",
        description: mutationError.message,
        status: "error",
        duration: 15000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Replace invoice succeeded",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    refetch();
  };

  return (
    <Grid
      templateAreas={{
        base: `"options"
           "preview"`,
        md: `"preview options"`,
      }}
      gridTemplateRows={{ base: "400px 2fr", md: "1fr" }}
      gridTemplateColumns={{ base: "1fr", md: "1fr 500px" }}
      height="100vh"
    >
      <GridItem gridArea="preview" bg="#FAFCFF">
        <Preview
          url={invoice.mediaAttachment?.media?.url}
          isLoading={loading}
        />
      </GridItem>

      <GridItem
        gridArea="options"
        py={7}
        borderLeft="1px solid #EFF2F6"
        overflow="auto"
        bg="#FAFCFF"
        color="#3A404A"
      >
        <Flex px={8} align="center" position="relative">
          <Text fontSize="2xl" fontWeight="bold" mr={4}>
            Invoice #{invoice.salesOrderNumber}
          </Text>

          <Box>
            <InvoiceStateBadge
              state={invoice.state}
              px={3}
              py={1}
              rounded="md"
            />
          </Box>

          <Button
            onClick={() => navigate(-1)}
            variant="ghost"
            fontSize="xs"
            right={3}
            position="absolute"
            rounded="full"
          >
            <CloseIcon />
          </Button>
        </Flex>
        <Text px={8} mb={4} fontWeight={600}>
          {invoice.customer.firstName} {invoice.customer.lastName}
        </Text>

        {invoice.mediaAttachment?.uploadedBy && (
          <Flex align="center" color="#8A92A0" fontSize="sm" px={8}>
            Submitted by {invoice.mediaAttachment?.uploadedBy}
          </Flex>
        )}
        {invoice.state === SpokeInvoiceState.Pending && (
          <Text fontSize="sm" color="#8A92A0" px={8} mb={4}>
            Paid on {DateTime.fromISO(invoice.stateUpdatedAt).toLocaleString()}
          </Text>
        )}
        {(invoice.state === SpokeInvoiceState.Pending ||
          invoice.state === SpokeInvoiceState.NeedsReview) && (
          <Stack
            borderY="1px solid #EFF2F6"
            py={4}
            px={8}
            gap={4}
            direction="row"
          >
            <Box w="100%">
              <FileUploader
                automaticUpload
                labelText="Replace invoice"
                buttonModeEnabled
                onUploadComplete={(file) => handleUploadComplete(file.url)}
              />
            </Box>
          </Stack>
        )}

        <Section
          title="New Lease"
          subtitle={`${invoice.lease?.termLengthMonths} months - $${monthlyTotalAmount}/mo`}
        >
          <VStack alignItems="start" spacing={4}>
            <Box>
              <Text color="#8A92A0" fontSize="sm">
                Buy Out
              </Text>
              <Text>
                For ${buyoutAmount} on{" "}
                {DateTime.fromISO(invoice.lease?.buyoutDate).toLocaleString()}
              </Text>
            </Box>

            <Box>
              <Text color="#8A92A0" fontSize="sm">
                Model
              </Text>
              <Text>{invoice.vehicleModel}</Text>
            </Box>

            <Box>
              <Text color="#8A92A0" fontSize="sm">
                Variant
              </Text>

              <HStack spacing="2">
                <Text>{invoice.vehicleSize}</Text>
                <Text>•</Text>
                <Text>{invoice.vehicleColor}</Text>
                <Text>•</Text>
                <Text>{invoice.vehicleDescription}</Text>
              </HStack>
            </Box>

            <Box>
              <Text color="#8A92A0" fontSize="sm">
                Serial Number
              </Text>
              <Text>{invoice.vehicleSerialNumber}</Text>
            </Box>
          </VStack>
        </Section>

        <Section title="Charges">
          <VStack alignItems="start" spacing={4}>
            <HStack w="full" justify="space-between">
              <Text>Vehicle retail price</Text>
              <Text>
                {formatMoney({
                  amount: invoice.vehicleRetailPriceCents / 100,
                  currencyCode: CurrencyCode.Usd,
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between">
              <Text>Accessories price</Text>
              <Text>
                {formatMoney({
                  amount: invoice.accessoriesRetailPriceCents / 100,
                  currencyCode: CurrencyCode.Usd,
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between" fontWeight="bold">
              <Text>Subtotal</Text>
              <Text>
                {formatMoney({
                  amount:
                    (invoice.accessoriesRetailPriceCents +
                      invoice.vehicleRetailPriceCents) /
                    100,
                  currencyCode: CurrencyCode.Usd,
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between">
              <Text>Ridepanda Discount ({invoice.discountPercentage}%)</Text>
              <Text>
                {formatMoney({
                  amount: invoice.discountCents / 100,
                  currencyCode: CurrencyCode.Usd,
                })}
              </Text>
            </HStack>
            {!!invoice.salesTaxCents && (
              <HStack w="full" justify="space-between">
                <Text>Sales Tax ({invoice.salesTaxPercentage}%)</Text>
                <Text>
                  {formatMoney({
                    amount: invoice.salesTaxCents / 100,
                    currencyCode: CurrencyCode.Usd,
                  })}
                </Text>
              </HStack>
            )}
            <HStack w="full" justify="space-between" fontWeight="bold">
              <Text>Total</Text>
              <Text>
                {formatMoney({
                  amount: invoice.totalCents / 100,
                  currencyCode: CurrencyCode.Usd,
                })}
              </Text>
            </HStack>
          </VStack>
        </Section>
      </GridItem>
    </Grid>
  );
}

export default SpokeInvoiceDetails;
