import { Flex } from "@ridepanda/bamboo-ui";

type BadgeType = "default" | "success" | "error";
type Props = { children: React.ReactNode; type?: BadgeType };

export default function BambooBadge({ children, type = "default" }: Props) {
  let bgColor: string;
  let color: string;

  switch (type) {
    case "success":
      bgColor = "#CEF7DD";
      color = "#00AD50";
      break;
    case "error":
      bgColor = "#FFD5C8";
      color = "#FF4200";
      break;
    default:
      bgColor = "#F9F7F4";
      color = "#92857B";
  }

  return (
    <Flex
      align="center"
      justify="center"
      style={{
        color,
        background: bgColor,
        paddingTop: "2px",
        paddingBottom: "2px",
        borderRadius: "8px",
      }}
      pl="4"
      pr="8"
    >
      {children}
    </Flex>
  );
}
