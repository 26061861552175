import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  type PaginationState,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  type InputMaybe,
  type SortDirection,
  type SpokeInvoiceForTableFragment,
  type SpokeInvoicesSortColumn,
  useSpokeInvoicesQuery,
} from "../../../api/types-and-hooks";
import BackArrowCircleIcon from "../../../components/Icons/BackArrowCircleIcon";
import DoubleBackArrowCircleIcon from "../../../components/Icons/DoubleBackArrowCircleIcon";
import DoubleForwardArrowCircleIcon from "../../../components/Icons/DoubleForwardArrowCircleIcon";
import ForwardArrowCircleIcon from "../../../components/Icons/ForwardArrowCircleIcon";
import { trackEvent } from "../../../services/heap";
import Columns from "./Columns";

export default function InvoicesTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const navigate = useNavigate();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const sortInfo = useMemo(() => {
    const { id: column, desc } = sorting[0] || {
      id: null,
      desc: null,
    };

    const direction = desc === false ? "asc" : "desc";

    return {
      sortColumn: column as InputMaybe<SpokeInvoicesSortColumn>,
      sortDirection: direction as InputMaybe<SortDirection>,
    };
  }, [sorting]);

  const fetchDataOptions = {
    currentPage: pageIndex + 1,
    pageSize,
    ...sortInfo,
  };

  const { data } = useSpokeInvoicesQuery({
    variables: fetchDataOptions,
  });

  const defaultData = useMemo(() => [], []);

  const items = data?.spokeInvoices?.pageInfo.totalItems;
  const totalPages = data?.spokeInvoices?.pageInfo.totalPages;

  const pagination = useMemo(
    () => ({ pageIndex, pageSize, items, totalPages }),
    [pageIndex, pageSize, items, totalPages],
  );

  const tableData = (data?.spokeInvoices?.invoices ??
    defaultData) as SpokeInvoiceForTableFragment[];

  const onRowClick = (invoiceId: string) => {
    trackEvent("Select Spoke Invoice", { id: invoiceId });
    navigate(`/spoke_invoices/${invoiceId}`);
  };

  const table = useReactTable({
    data: tableData,
    pageCount: data?.spokeInvoices?.pageInfo.totalPages ?? -1,
    columns: Columns,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    manualPagination: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <Table size="lg">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id} display="flex">
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                color="panda.500"
                backgroundColor="#F8F5EE"
                borderBottomColor="#D9D9D9"
                borderTop="1px solid #D9D9D9"
                cursor={header.column.getCanSort() ? "pointer" : undefined}
                onClick={header.column.getToggleSortingHandler()}
                w={`${header.column.getSize()}px`}
                flexGrow={header.column.getSize()}
                flexBasis="auto"
                flexShrink={0}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {{
                  asc: " 🔼",
                  desc: " 🔽",
                }[header.column.getIsSorted() as string] ?? null}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr
            key={row.id}
            display="flex"
            cursor="pointer"
            pointerEvents="auto"
            _hover={{ bgColor: "#746b5d24" }}
            onClick={() => onRowClick(row.original.id)}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                py={8}
                color="panda.500"
                borderBottomColor="#D9D9D9"
                flexGrow={cell.column.getSize()}
                flexBasis="auto"
                flexShrink={0}
                w={`${cell.column.getSize()}px`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        {pagination.items && (
          <Tr
            borderBottom="1px solid #D9D9D9"
            backgroundColor="#F8F5EE"
            display="flex"
            justifyContent="space-between"
          >
            <Th
              colSpan={4}
              color="panda.500"
              textTransform="none"
              fontSize="md"
              fontWeight="normal"
              py={3}
            >
              {pagination.items} invoices displayed
            </Th>
            <Th
              colSpan={2}
              color="panda.500"
              textTransform="none"
              fontSize="md"
              fontWeight="normal"
              py={3}
            >
              <HStack justify="flex-end" spacing={4}>
                <ButtonGroup>
                  <Button
                    aria-label="Go to the first page"
                    variant="link"
                    minW="min-content"
                    onClick={() => table.setPageIndex(0)}
                  >
                    <DoubleBackArrowCircleIcon size="18px" />
                  </Button>
                  <Button
                    aria-label="Go to the previous page"
                    variant="link"
                    minW="min-content"
                    onClick={() => table.previousPage()}
                  >
                    <BackArrowCircleIcon size="18px" />
                  </Button>
                </ButtonGroup>
                <Text>
                  Page {pagination.pageIndex + 1} of {pagination.totalPages}
                </Text>
                <ButtonGroup>
                  <Button
                    aria-label="Go to the next page"
                    variant="link"
                    minW="min-content"
                    onClick={() => table.nextPage()}
                  >
                    <ForwardArrowCircleIcon size="18px" />
                  </Button>
                  <IconButton
                    aria-label="Go to the last page"
                    variant="link"
                    minW="min-content"
                    onClick={() =>
                      table.setPageIndex(totalPages ? totalPages - 1 : -1)
                    }
                  >
                    <DoubleForwardArrowCircleIcon size="18px" />
                  </IconButton>
                </ButtonGroup>
              </HStack>
            </Th>
          </Tr>
        )}
      </Tfoot>
    </Table>
  );
}
