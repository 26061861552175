import { Icon, type IconProps } from "@chakra-ui/react";

const SquareBackOutlineIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 6L6 10M6 10L10 14M6 10H14M10 1C17.2 1 19 2.8 19 10C19 17.2 17.2 19 10 19C2.8 19 1 17.2 1 10C1 2.8 2.8 1 10 1Z"
      fill="transparent"
      stroke="#332B04"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default SquareBackOutlineIcon;
